import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {GlobalConstants} from '../../../constants/global.constants';

@Component({
  selector: 'app-cea',
  templateUrl: './cea.component.html',
  styleUrls: ['./cea.component.scss']
})
export class CeaComponent implements OnDestroy {


  public APP = GlobalConstants;

  protected destroyed$ = new Subject<void>();


  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }



}
