import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// CEA
import { EventsComponent } from './events/events.component';
import { ContactsComponent } from './contacts/contacts.component';

import { LoginModule } from 'node_modules/cs'
import { AddHeaderInterceptor, LangInterceptor, IllegalCharactersInterceptor, EncodeInterceptor } from 'node_modules/cs'

import { AppInitService } from './app-init.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';

import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared';
import { FormsModule } from '@angular/forms';
import { UtilitiesService } from './services/utilities.service';
import { SwiperModule } from 'swiper/angular';
import { UserProfileService } from './services/user-profile.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/frontend/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EventsComponent,
    ContactsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LoginModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    SharedModule,
    SwiperModule,
	  FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-EN',
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient],
      }
  })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCore,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LangInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IllegalCharactersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeInterceptor,
      multi: true,
    },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    AppInitService,
    DatePipe,
    {provide : 'UtilitiesService', useExisting : UtilitiesService},
    {provide : 'UserProfileService', useExisting : UserProfileService},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'},
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeCore(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}
