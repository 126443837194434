import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadFileComponent } from '../../upload-file/upload-file.component';

@Component({
  selector: 'app-dialog-simple-add-document',
  templateUrl: './dialog-simple-add-document.component.html',
  styleUrls: ['./dialog-simple-add-document.component.scss']
})
export class DialogSimpleAddDocumentComponent implements OnInit {
  //Upload Document
  public animation: boolean = false;
  public multiple: boolean = false;

  @ViewChild(UploadFileComponent) uploadFileComponent: any;

  uploadedFiles: any;
  conf: any;
  dto : any;
  label: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
    private dialogRef: MatDialogRef<DialogSimpleAddDocumentComponent>) {

  }


  ngOnInit(): void {
    this.uploadedFiles = this.data.uploadedFiles;
    this.conf = this.data.conf;
    this.label = (this.conf && this.conf.type) ? this.conf.type : 'Document';
    this.dto = this.data.dto;
  }

  saveDocument(){
    const files = this.uploadFileComponent.returnFilesDocument();
    this.dialogRef.close({files: files});
  }

}

