
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { LoginService } from 'cs';
import {ClientService} from '../../../services/client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  public clientService: ClientService = this._ClientService;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private utilitiesService: UtilitiesService,
    private _ClientService: ClientService,
  ) {

  }

  ngOnInit(): void {
  }

  logout() {
    this.loginService.logout().subscribe((data: any) => {
      this.router.navigate([`login`]);
    });
  }

  openDialogMenu() {
    this.utilitiesService.openDialogMenu();
  }

  openDialogSupport() {
    this.utilitiesService.openDialogSupport({
      title: "Contact support",
      description: "<p>Hello!, You can raise a Support request from the options provided.</p>",
      user: "User Guide Manual",
      userDescription: "You can consult User Guide Manual any time.",
      doubt: "Report an issue or doubt",
      doubtDescription: "If you have any issue or doubt, please contact us at mail@havas.com.",
      /* suggestions: "Suggestions for improvement", */
      suggestionsDescription: "I have feedback to boost the user experience of the platform or a request for a new feature to be developed."
    }, '', 575);
  }

}
