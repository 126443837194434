export const EventConstants = {
  TYPE: 103,
  MT: {
    ROOT_ID: 3000,
    CLIENT_METADATA_ID: 3001,
    TYPOLOGY_METADATA_ID: 3002,
    ACTION_METADATA_ID: 3003,
    BRAND_SEGMENT_METADATA_ID: 3004,
    NAME_METADATA_ID: 3101,
    MARKET_METADATA_ID: 3006,
    ADDRESS_METADATA_ID: 3103,
    START_DATE_METADATA_ID: 3104,
    END_DATE_METADATA_ID: 3105,
    DUE_DATE_METADATA_ID: 3106,
    DESCRIPTION_METADATA_ID: 3107,
    ATTENDING_CLIENT_METADATA_ID: 3201,
    ATTENDING_HAVAS_METADATA_ID: 3202,
    OWNER_METADATA_ID: 3203,
    SCALE_METADATA_ID: 3401,
    PRAPARATION_STATUS_METADATA_ID: 3402,
    MONTH_METADATA_ID: 3403
  },
  MC: {
    PREPARATION_STATUS_EVENT_CREATED_ID: 3421,
    PREPARATION_STATUS_EVENT_IN_PROGRESS_ID: 3422,
    PREPARATION_STATUS_EVENT_COMPLETED: 3423,
    PREPARATION_STATUS_EVENT_CANCELLED: 3424
  }

};
