import { Component, OnInit, Input, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { environment } from '@environment';
import { services } from '@services';
import { UtilsService } from 'cs';

export type AvatarSize = 'xs' | 'sm' | 'lg' | 'xl' | null;
export type AvatarColor = 'success' | 'warning' | 'danger' | null;
export type AvatarTheme = 'dark' | 'light' | null;

@Component({
  selector: '[app-avatar]',
  template: `
    <span [ngClass]="'c-avatar__wrap'">
      <img appBackgroundImage [mail]="mailSrc" [contentId]="contentId" [imgSrc]="imgSrc" *ngIf="showThumbnail">
      <ng-container *ngIf="initials!=='' && !showThumbnail">{{initials}}</ng-container>
    </span>
  `,
  host: {
    '[class.c-avatar--xs]': 'size === "xs"',
    '[class.c-avatar--sm]': 'size === "sm"',
    '[class.c-avatar--lg]': 'size === "lg"',
    '[class.c-avatar--xl]': 'size === "xl"',
    '[class.c-avatar--dark]': 'theme === "dark"',
    '[class.c-avatar--light]': 'theme === "light"',
    '[class.c-avatar--initials]': 'initials !== ""',
  }
})
export class AvatarComponent implements OnInit, OnChanges {

  @Input() size: AvatarSize = null;
  @Input() color: AvatarColor = null;
  @Input() theme: AvatarTheme = null;
  @Input() imgSrc: string = '';
  @Input() initials: string = '';
  @Input() contentId: number = 0;

  mailSrc: any;

  defaultUrl: string = '';
  showThumbnail: boolean = false;

  constructor(private utilsService: UtilsService) { }

  @HostBinding('class') classes = 'c-avatar';

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.contentId?.currentValue) {
      this.showThumbnail = true;
    } else if (!this.imgSrc && !this.initials) {
      this.imgSrc = 'assets/img/brand.dark.svg';
      this.showThumbnail = true;
    } else if (this.initials) {
      this.showThumbnail = false;
      this.imgSrc = '';
    } else if (this.imgSrc) {
      this.showThumbnail = true;
    }
  }

}
