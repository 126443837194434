export const DialogConstants = {
  DELETE_DOCUMENT:      {type: 'CONFIRMATION', title:'Delete Document', description:'Are you sure you want to delete the document?', confirmationButtonName:'Delete'},
  APPROVE_DOCUMENT:     {type: 'CONFIRMATION', title:'Approve Document', description:'Are you sure you want to approve the document?', confirmationButtonName:'Approve'},
  REJECT_DOCUMENT:      {type: 'CONFIRMATION', title:'Reject Document', description:'Are you sure you want to reject the document?', confirmationButtonName:'Reject'},
  SAVE_ERROR:           {title:'Save Error'},
  DELETE_ERROR:         {title:'Delete Error'},
  DUPLICATE_USER :      {title : 'APP.DIALOG.CORE.TITLE.SAVE_ERROR', description: 'APP.DIALOG.DESCRIPTION.USER.ALREADY.EXIST'},
  CONFIRM_ACTIVE_ITEM:  {type: 'CONFIRMATION', title:'Change Visibility', description:'Are you sure you want to change the item visibility?', confirmationButtonName:'Ok'},
  LOGIN_ERROR:          {title:' Login Error', description: "Login Error"},
  HELP:                 {type: "INFO", title: 'Contact Support', description: 'If you have any issue or doubt, please contact us at support.clientexperience@havasmg.com'},
};
