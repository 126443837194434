export const ContactsConstants = {
  TYPE: 1,
  MT: {
    ROOT_ID: 3001,
    CLIENT_METADATA_ID : 3002,
    INDUSTRY_METADATA_ID : 3004,
    CAMPAIGNGEOGRAPHY_METADATA_ID : 3006,
    AGENCY_METADATA_ID : 3009,
  },
  MC: {
    ROOT_CONTENT_ID: 3001,
    DRAFT_TRUE : 3180,
    DRAFT_FALSE: 3181,
  },
  CONTRIBUTION_FORM: [

  ]

};
