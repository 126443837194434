import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventConstants } from '../constants/event.constants';
import { DialogService, MetadataSearchDto, UtilsService } from 'cs';
import { Event } from '../models/event.model';
import { GenericGlobalService } from './generic-global.service';
import { map } from 'rxjs/operators';
import { MyEvent } from '../models/event-my.model';
import { services } from '@services';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  searchDto: MetadataSearchDto = {
    pagination: {
      page: 0,
      itemsPerPage: 20
    },
    lang: 'en',
    resourcePermission: 1,
    globalResourceTypeId: EventConstants.TYPE,
    metadataMapSearchOr: new Map<number, number[]>(),
    metadataMapSearchAnd: new Map<number, number[]>(),
    metadataContentIdsSearch: new Map<number, number[]>(),
    metadataMapSearchOrMetadataOr: new Map<number, string[]>(),
    metadataIdsBetween: new Map<number, number[]>(),
    metadataMapOrder: [{ [EventConstants.MT.START_DATE_METADATA_ID]: 'ASC' }]
  };

  eventListObservable$ = new Subject<any>();
  eventListObservable = this.eventListObservable$.asObservable();
  updateEventList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentEvent$: BehaviorSubject<null | MyEvent> = new BehaviorSubject<null | MyEvent>(null);
  eventsList: Event[] = [];

  constructor(
    private genericGlobalService: GenericGlobalService,
    private dialogService: DialogService,
    private utilsService: UtilsService,
  ) {
  }

  getListEventInformation(searchDto?: MetadataSearchDto, fromScroll: boolean = false) {
    // this.dialogService.openSpinner();

    const search: any = searchDto || { ...this.searchDto };
    this.genericGlobalService.getAll(search, fromScroll).subscribe(
      (res: any) => {
        this.eventsList = res.valuesResults;
        this.eventListObservable$.next(this.eventsList);
        this.dialogService.close()
      }), (e: any) => {
        console.log(e);
        this.dialogService.close();
      };
  }

  getEventById(id: number): void {
    this.genericGlobalService.getOne(id, EventConstants.TYPE).pipe(
      map((event: any) => event?.valuesResults.length ? new MyEvent(event.valuesResults[0]) : null)
    ).subscribe(event => {
      this.currentEvent$.next(event);
    });
  }

  addSearchParameterForDraft() {
    return this.utilsService.cloneObj(this.searchDto);
  }

  updateEventList() {
    setTimeout(() => {
      // reset value
      this.updateEventList$.next(false);
    }, 100);
    this.updateEventList$.next(true);
  }

  getContactList(cliId: number): Observable<any> {
    return this.genericGlobalService.getUrl(`${services.CONTACT_LIST}${cliId}`);
  }

  deleteEVent(id: number): Observable<any> {
    return this.genericGlobalService.delete(id, EventConstants.TYPE);
  }

  destroyCurrentEvent() {
    this.currentEvent$.next(null);
  }

  // getListEventInformation() {
  //   // this.dialogService.openSpinner();
  //   let search: any = { ...this.searchDto };
  //   this.genericGlobalService.getAll(search, false).subscribe(
  //     (res: any) => {
  //       this.eventsList = res.valuesResults;
  //       this.eventListObservable$.next(this.eventsList);
  //       this.dialogService.close()
  //     });
  // }

  //   getListEventInformation(searchDto: any, fromScroll: boolean) {
  //     this.genericGlobalService.getAll(searchDto, false).subscribe(
  //       (res:any)=>{
  //         this.eventsList = res.valuesResults;
  //         this.eventListObservable$.next(this.eventsList);
  //         this.dialogService.close();
  //       });
  // }
  // IEA-26 - Hiberus - Calendar View
  // Start Date	1667343600000	11267	3104
  // End Date	1667602800000	11268	3105
  // Due Date	1667257200000	11269	3106
  //   "metadataIdsBetween":{"3104":[null,1670630399000]}
  // 42 huecos de calendario
  // calendarApi.currentData.currentDate.getTime()
  // getListEventMonthCalendar(date: Date, clientId: number) {
  //   this.dialogService.openSpinner();
  //   let search: MetadataSearchDto = {...this.searchDto};
  //   search.metadataIdsBetween = this.dateService.getMonthInterval(
  //     date,
  //     GlobalConstants.EVENTS.MT.START_DATE_METADATA_ID,
  //     true,
  //     true
  //   );
  //   search.metadataContentIdsSearch = {};
  //   search.metadataContentIdsSearch[GlobalConstants.EVENTS.MT.CLIENT_METADATA_ID] = [clientId];
  //
  //   this.genericGlobalService.getAll(search, false).subscribe(
  //     (res: any) => {
  //       this.eventsList = res.valuesResults;
  //       this.eventListObservable$.next(this.eventsList);
  //       this.dialogService.close()
  //     });
  // }
}
