<div class="mat-dialog-header">
  <h3 mat-dialog-title>{{data.title}}</h3>
  <svg class="icon ic-20" mat-dialog-close><use href="assets/icons/icons-sprite.svg#close"/></svg>
</div>

<mat-dialog-content>

<p>{{data.description}}</p>

</mat-dialog-content>

<mat-dialog-actions align="end" *ngIf="data.type==='CONFIRMATION'">
  <button class="btn btn-outline-dark" mat-dialog-close>Cancel</button>
  <button class="btn btn-secondary" [mat-dialog-close]="true" cdkFocusInitial>{{data.confirmationButtonName}}</button>
</mat-dialog-actions>
