/* It's a service that provides a list of clients, and a selected client */
import { Injectable } from '@angular/core';
import { MetadataSearchService } from 'cs';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { GlobalConstants } from '../constants/global.constants';
import { TreeMetadata } from '../models/tree-metadata.model';
import { OrderByPipe } from '../shared/pipes/order-by.pipe';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  itemByContactMap = new Map();

  items: any[] = [];
  private _itemSelected: any;

  idSelected$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  idSelectedByContact$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  idSelectedByEvent$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  itemSelected$: BehaviorSubject<TreeMetadata> = new BehaviorSubject<TreeMetadata>(new TreeMetadata());
  itemSelectedByContact$: BehaviorSubject<TreeMetadata> = new BehaviorSubject<TreeMetadata>(new TreeMetadata());
  itemSelectedByEvent$: BehaviorSubject<TreeMetadata> = new BehaviorSubject<TreeMetadata>(new TreeMetadata());

  /**
   *
   * @param value
   */
  set itemSelected(value: any) {
    if (!(!this._itemSelected && !value)) {
      this.itemSelected$.next(value);
      this.itemSelectedByEvent$.next(value);
      if (this.itemByContactMap.has(value.metadataContentText)) {
        this.itemSelectedByContact$.next(this.itemByContactMap.get(value.metadataContentText));
        this.idSelectedByContact$.next(this.itemByContactMap.get(value.metadataContentText).metadataContentId);
      }
      this.idSelected$.next(+value.metadataContentId);
      this.idSelectedByEvent$.next(value.metadataContentId);
    }
    this._itemSelected = value;
  }

  /**
   *
   */
  get itemSelected(): any {
    return this._itemSelected;
  }

  get itemSelectedByEvent(): any {
    return this._itemSelected;
  }

  get itemSelectedByContact(): any {
    if (!this._itemSelected) {
      return null;
    }
    if (!this._itemSelected.metadataContentText) {
      return null;
    }
    if (!this.itemByContactMap.has(this._itemSelected.metadataContentText)) {
      return null;
    }
    return this.itemByContactMap.get(this._itemSelected.metadataContentText);
  }

  get idSelectedByEvent(): any {
    return this.itemSelectedByEvent ? this.itemSelectedByEvent.metadataContentId : null;
  }

  get idSelectedByContact(): any {
    return this.itemSelectedByContact ? this.itemSelectedByContact.metadataContentId : null;
  }

  constructor(
    private _MetadataSearchService: MetadataSearchService
  ) {
    this.init();
  }

  private init() {

    forkJoin([this.getClients(), this.getClientsByContact()]).subscribe(
      ([clientEvents, clientContacts]: [TreeMetadata[], TreeMetadata[]]) => {
        
        if (clientEvents && clientContacts) {
          // Vaciarlo sin perder la referencia
          this.items.splice(0, this.items.length);
          this.itemSelected = undefined;

          // Asignar los nuevos valores // Ordenar por nombre
          this.items.push(...new OrderByPipe().transform(clientEvents, 'metadataContentText'));

          // Vaciarlo sin perder la referencia
          this.itemByContactMap.clear();

          // Asignar los nuevos valores
          clientContacts.forEach((item: any) => {
            if (
              item
              && item.metadataContentText
              && item.metadataContentId
            ) {
              this.itemByContactMap.set(item.metadataContentText, item);
            }
          });

          if (this.items.length > 0) {
            this.itemSelected = this.items[0];
          }

        }
      });

  }

  private getClients(): Observable<TreeMetadata[]> {
    return this._MetadataSearchService.getGRTreeMetadataOneLevelByMetadata(
      GlobalConstants.CLIENT.BY_EVENT.PARENT_ID,
      GlobalConstants.CLIENT.BY_EVENT.METADATA_ID
    );
  }

  private getClientsByContact(): Observable<TreeMetadata[]> {
    return this._MetadataSearchService.getGRTreeMetadataOneLevelByMetadata(
      GlobalConstants.CLIENT.BY_CONTACT.PARENT_ID,
      GlobalConstants.CLIENT.BY_CONTACT.METADATA_ID
    );
  }
}
