import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// Flex Layout
import {FlexLayoutModule} from '@angular/flex-layout';

// Swiper
import {SwiperModule} from 'swiper/angular';

// 3 Party
import {FileUploadModule} from '@iplab/ngx-file-upload';

// Material
import {MatNativeDateModule} from '@angular/material/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';


import {CdkAccordionModule} from '@angular/cdk/accordion';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { BackgroundImageDirective } from './directives/background-image.directive';

import { DialogMenuComponent } from './components/dialogs/dialog-menu/dialog-menu.component';

import {HeaderComponent} from './layout/header/header.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {AvatarComponent} from './components/avatar/avatar.component';
import {UploadFileComponent} from './components/upload-file/upload-file.component';
import {DialogSaveButtonsComponent} from './components/dialogs/dialog-save-buttons/dialog-save-buttons.component';
import {DialogMessageComponent} from './components/dialogs/dialog-message/dialog-message.component';
import {DialogAddDocumentComponent} from './components/dialogs/dialog-add-document/dialog-add-document.component';
import {DialogAddEventComponent} from './components/dialogs/dialog-add-event/dialog-add-event.component';
import {DialogAddContactComponent} from './components/dialogs/dialog-add-contact/dialog-add-contact.component';
import {DialogSendApprovalComponent} from './components/dialogs/dialog-send-approval/dialog-send-approval.component';
import {DialogGenericComponent} from './components/dialogs/dialog-generic/dialog-generic.component';
import {DialogSupportComponent} from './components/dialogs/dialog-support/dialog-support.component';
import {StateBadgeComponent} from './components/state-badge/state-badge.component';
import {ToggleStateComponent} from './components/toggle-state/toggle-state.component';
import {
  DataLabelActionsDirective,
  DataLabelCaptionDirective,
  DataLabelDirective,
  DataLabelGroupDirective,
  DataLabelTextDirective
} from './components/data-label/data-label.directive';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {MetadataFiltersComponent} from './components/metadata-filters/metadata-filters.component';
import {UserCardComponent} from './components/user-card/user-card.component';
import {DialogAddImageComponent} from './components/dialogs/dialog-add-image/dialog-add-image.component';
import {DialogAlertComponent} from './components/dialogs/dialog-alert/dialog-alert.component';
import {ChangeVisibilityComponent} from './components/grid/actions/change-visibility/change-visibility.component';
import {EditItemComponent} from './components/grid/actions/edit-item/edit-item.component';
import {
  DialogSimpleAddDocumentComponent
} from './components/dialogs/dialog-simple-add-document/dialog-simple-add-document.component';
import {RandomImgComponent} from './components/random-img/random-img.component';
import {ListViewCardComponent} from './components/list-view-card/list-view-card.component';
import {ListViewCardContactComponent} from './components/list-view-card-contact/list-view-card-contact.component';

import {DirectivesModule, PipesModule} from 'cs';
import {AvatarMultipleComponent} from './components/avatar-multiple/avatar-multiple.component';
import {TranslateModule} from '@ngx-translate/core';
import {GanttColComponent} from './components/gantt-col/gantt-col.component';
import {AddRepeatComponent} from './components/forms/add-repeat/add-repeat.component';
import {ListViewShortComponent} from './components/list-view-short/list-view-short.component';
import {MonthColComponent} from './components/month-col/month-col.component';
//import { CardLargeComponent } from './components/card-large/card-large.component';
import {FullCalendarModule} from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import {EventLiteComponent} from './components/event-lite/event-lite.component';
import {TaskComponent} from './components/task/task.component';
import {TasksGroupComponent} from './components/tasks-group/tasks-group.component';
import {ContactCardComponent} from './components/contact-card/contact-card.component';
import {CardsGroupComponent} from './components/cards-group/cards-group.component';
import {FormsTasksComponent} from './components/forms/forms-tasks/forms-tasks.component';
import {NoResultsComponent} from './components/no-results/no-results.component';
import {AddNoteComponent} from './components/forms/add-note/add-note.component';
import {InitialsPipe} from './pipes/initials.pipe';
import {MasterTableComponent} from './components/grid/master-table/master-table.component';
import {WhitespacesDirective} from './directives/white-spaces-validator.directive';
import {DueDateValidatorDirective} from './directives/due-date-validator.directive';
import {CeaComponent} from './components/cea/cea.component';
import {CeaEventComponent} from './components/cea/cea-event/cea-event.component';
import {GraphComponent} from './components/relation/graph/graph.component';
import {EventGanttFilterDatePipe} from './pipes/event-gantt-filter-date.pipe';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {OrderByPipe} from './pipes/order-by.pipe';
import { DemoHiddenDirective } from './directives/demo-hidden.directive';
import { EventCardComponent } from './components/event-card/event-card.component';
import { NotesCardComponent } from './components/notes-card/notes-card.component';
import { OrganizationChartComponent } from './components/organization-chart/organization-chart.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

const material = [
  MatNativeDateModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule,
  MatAutocompleteModule,
  CdkAccordionModule,
  DragDropModule,
  MatMenuModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  ScrollingModule,
]

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FullCalendarModule,
    SwiperModule,
    FileUploadModule,
    DirectivesModule,
    PipesModule,
    TranslateModule,
    InfiniteScrollModule,
    material
  ],
  declarations: [
    HeaderComponent,
    AvatarComponent,
    DialogAddDocumentComponent,
    DialogAddEventComponent,
    DialogAddContactComponent,
    DialogSendApprovalComponent,
    SidebarComponent,
    UploadFileComponent,
    DialogSaveButtonsComponent,
    DialogMessageComponent,
    DialogGenericComponent,
    StateBadgeComponent,
    DataLabelDirective,
    DataLabelCaptionDirective,
    DataLabelTextDirective,
    DataLabelActionsDirective,
    DataLabelGroupDirective,
    UserProfileComponent,
    MetadataFiltersComponent,
    UserCardComponent,
    DialogAddImageComponent,
    MetadataFiltersComponent,
    DialogAlertComponent,
    ChangeVisibilityComponent,
    EditItemComponent,
    DialogSimpleAddDocumentComponent,
    RandomImgComponent,
    ListViewCardComponent,
    ListViewCardContactComponent,
    BackgroundImageDirective,
    NoResultsComponent,
    AvatarMultipleComponent,
    DialogSupportComponent,
    DialogMenuComponent,
    GanttColComponent,
    AddRepeatComponent,
    ListViewShortComponent,
    MonthColComponent,
    EventLiteComponent,
    ToggleStateComponent,
    TaskComponent,
    TasksGroupComponent,
    ContactCardComponent,
    CardsGroupComponent,
    FormsTasksComponent,
    AddNoteComponent,
    MasterTableComponent,
    InitialsPipe,
    DueDateValidatorDirective,
    WhitespacesDirective,
    CeaComponent,
    CeaEventComponent,
    GraphComponent,
    EventGanttFilterDatePipe,
    OrderByPipe,
    DemoHiddenDirective,
    EventCardComponent,
    NotesCardComponent,
    OrganizationChartComponent,
  ],
  exports: [
    HeaderComponent,
    ListViewCardComponent,
    ListViewCardContactComponent,
    AvatarComponent,
    FlexLayoutModule,
    FullCalendarModule,
    SwiperModule,
    FileUploadModule,
    SidebarComponent,
    StateBadgeComponent,
    DataLabelDirective,
    DataLabelCaptionDirective,
    DataLabelTextDirective,
    DataLabelActionsDirective,
    DataLabelGroupDirective,
    material,
    UserProfileComponent,
    MetadataFiltersComponent,
    UserCardComponent,
    UploadFileComponent,
    MetadataFiltersComponent,
    GanttColComponent,
    ListViewShortComponent,
    MonthColComponent,
    ToggleStateComponent,
    TaskComponent,
    TasksGroupComponent,
    ContactCardComponent,
    CardsGroupComponent,
    NoResultsComponent,
    MasterTableComponent,
    InfiniteScrollModule,
    GraphComponent,
    EventGanttFilterDatePipe,
    DemoHiddenDirective,
    EventCardComponent,
    NotesCardComponent,
    OrganizationChartComponent,
  ]
})
export class SharedModule {
}
