export const environment = {
    production: false,
    offline: true,
    apiEndpoint: 'https://frontend-9hn.pages.dev/assets/offline',
    oauthEndpoint: 'https://frontend-9hn.pages.dev/assets/offline',
    clientEndpoint: 'https://frontend-9hn.pages.dev/',
    havasAnalytics: '',
    appSecret: '1234ASWFV12QSDAE31ASFASR_AX21',
    sessionTime: 1800000, // 30 minutes * 60000 //time in milliseconds
    nameStorage: 'cea',
    appName: 'CEA'
};
