<div class="mat-dialog-header">
  <h3 mat-dialog-title>{{data.title}}</h3>
  <svg class="icon ic-20" mat-dialog-close><use href="assets/icons/icons-sprite.svg#close"/></svg>
</div>

<mat-dialog-content class="c-support__content">

  <div [innerHTML]="data.description"></div>
  <div class="c-support__list mt-5">
    <a href="./assets/files/cea-guide.pdf" role='button' class="c-support__item" download="cea-user-guide-manual.pdf">
      <svg class="icon ic-20" mat-dialog-close><use href="assets/icons/icons-sprite.svg#document"/></svg>
      <div class="c-data-label">
        <span class="c-data-label__text">{{data.user}}</span>
        <span class="c-data-label__caption">{{data.userDescription}}</span>
      </div>
    </a>
    <a role='button' class="c-support__item" (click)="openReportIssueDialog()">
      <svg class="icon ic-20" mat-dialog-close><use href="assets/icons/icons-sprite.svg#info_square"/></svg>
      <div class="c-data-label">
        <span class="c-data-label__text">{{data.doubt}}</span>
        <span class="c-data-label__caption">{{data.doubtDescription}}</span>
      </div>
    </a>
    <!-- <a href="#" target="_blank" class="c-support__item">
      <svg class="icon ic-20" mat-dialog-close><use href="assets/icons/icons-sprite.svg#voice"/></svg>
      <div class="c-data-label">
        <span class="c-data-label__text">{{data.suggestions}}</span>
        <span class="c-data-label__caption">{{data.suggestionsDescription}}</span>
      </div>
    </a> -->
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="btn btn-outline-dark" [mat-dialog-close]="'Close'">Close</button>
</mat-dialog-actions>
