export const MasterConstants = {
  EVENTSTYPOLOGY: {
    FILTERS: {
      metadataContentText: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Name', label: 'CLIENT_ID' },
      author: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Author', label: 'AUTHOR_ID' },
      creationDate: { input: 'date', type: 'imple_datesRange', placeholder: 'Filter By Upload Date', label: 'creationDate' },
    },
    GRID: [
      {column: 'Client',      field: 'metadataIdText', type: 'text'},
      {column: 'Event Typology', field: 'metadataContentText', type: 'text'}

    ],
    CONTRIBUTION: [
      {column: 'Industry Name',  field: 'metadataContentText', input: 'text'},
      {column: 'Active',  field: 'active', input: 'checkbox'},
    ],
    PARENT_ID: 3001
  },
  ACTIONTYPE: {
    FILTERS: {
      metadataContentText: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Name', label: 'CLIENT_ID' },
      author: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Author', label: 'AUTHOR_ID' },
      creationDate: { input: 'date', type: 'imple_datesRange', placeholder: 'Filter By Upload Date', label: 'creationDate' },
    },
    GRID: [
      {column: 'Client',      field: 'metadataIdText', type: 'text'},
      {column: 'Action Type', field: 'metadataContentText', type: 'text'}

    ],
    CONTRIBUTION: [
      {column: 'Industry Name',  field: 'metadataContentText', input: 'text'},
      {column: 'Active',  field: 'active', input: 'checkbox'},
    ],
    PARENT_ID: 3001
  },
  BRANDSEGMENT: {
    FILTERS: {
      metadataContentText: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Name', label: 'CLIENT_ID' },
      author: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Author', label: 'AUTHOR_ID' },
      creationDate: { input: 'date', type: 'imple_datesRange', placeholder: 'Filter By Upload Date', label: 'creationDate' },
    },
    GRID: [
      {column: 'Client',      field: 'metadataIdText', type: 'text'},
      {column: 'Brand/Segment', field: 'metadataContentText', type: 'text'}

    ],
    CONTRIBUTION: [
      {column: 'Industry Name',  field: 'metadataContentText', input: 'text'},
      {column: 'Active',  field: 'active', input: 'checkbox'},
    ],
    PARENT_ID: 3001
  },
  RESPONSIBLE: {
    FILTERS: {
      metadataContentText: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Name', label: 'CLIENT_ID' },
      author: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Author', label: 'AUTHOR_ID' },
      creationDate: { input: 'date', type: 'imple_datesRange', placeholder: 'Filter By Upload Date', label: 'creationDate' },
    },
    GRID: [
      {column: 'Client',      field: 'metadataIdText', type: 'text'},
      {column: 'Brand/Segment', field: 'metadataContentText', type: 'text'}

    ],
    CONTRIBUTION: [
      {column: 'Industry Name',  field: 'metadataContentText', input: 'text'},
      {column: 'Active',  field: 'active', input: 'checkbox'},
    ],
    PARENT_ID: 3001
  },
  LEVEL: {
    FILTERS: {
      metadataContentText: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Name', label: 'CLIENT_ID' },
      author: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Author', label: 'AUTHOR_ID' },
      creationDate: { input: 'date', type: 'imple_datesRange', placeholder: 'Filter By Upload Date', label: 'creationDate' },
    },
    GRID: [
      {column: 'Client',      field: 'metadataIdText', type: 'text'},
      {column: 'Engagement Level', field: 'metadataContentText', type: 'text'}

    ],
    CONTRIBUTION: [
      {column: 'Industry Name',  field: 'metadataContentText', input: 'text'},
      {column: 'Active',  field: 'active', input: 'checkbox'},
    ],
    PARENT_ID: 3001
  },
  TYPE: {
    FILTERS: {
      metadataContentText: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Name', label: 'CLIENT_ID' },
      author: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Author', label: 'AUTHOR_ID' },
      creationDate: { input: 'date', type: 'imple_datesRange', placeholder: 'Filter By Upload Date', label: 'creationDate' },
    },
    GRID: [
      {column: 'Client',      field: 'metadataIdText', type: 'text'},
      {column: 'Client Category Type', field: 'metadataContentText', type: 'text'}

    ],
    CONTRIBUTION: [
      {column: 'Industry Name',  field: 'metadataContentText', input: 'text'},
      {column: 'Active',  field: 'active', input: 'checkbox'},
    ],
    PARENT_ID: 3001
  },
};
