import { Directive, Input, HostBinding } from '@angular/core';

export type DataLabelSize = 'xs' | 'sm' | 'lg' | null;

@Directive({
  selector: '[appDataLabel]',
  host: {
    '[class.c-data-label--xs]': 'size === "xs"',
    '[class.c-data-label--sm]': 'size === "sm"',
    '[class.c-data-label--lg]': 'size === "lg"',
  },
})
export class DataLabelDirective {

  @Input() size: DataLabelSize = null;

  @Input() textTruncate = true;

  @HostBinding('class') class = 'c-data-label';

  @HostBinding('class.c-data-label--truncate') get truncateClass() {
    return this.textTruncate;
  }

}

@Directive({
  selector: '[appDataLabelCaption]'
})
export class DataLabelCaptionDirective {

  @HostBinding('class') class = 'c-data-label__caption';

}

@Directive({
  selector: '[appDataLabelText]'
})
export class DataLabelTextDirective {

  @HostBinding('class') class = 'c-data-label__text';

}

@Directive({
  selector: '[appDataLabelActions]'
})
export class DataLabelActionsDirective {

  @HostBinding('class') class = 'c-data-label__actions';

}

@Directive({
  selector: '[appDataLabelGroup]'
})
export class DataLabelGroupDirective {

  @HostBinding('class') class = 'c-data-label-group';

}
