import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): unknown {
    if (!value) {
      return value;
    }

    const initials = value.toUpperCase().split(' ');

    return initials.length > 1
      ? `${initials[0].substring(0, 1)}${initials[initials.length - 1].substring(0, 1)}`
      : `${initials[0].substring(0, 1)}`;
  }

}
