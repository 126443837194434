<div class="mat-dialog-header">
  <h3 mat-dialog-title>Add {{conf.type}}</h3>
  <svg class="icon ic-20" mat-dialog-close>
    <use href="assets/icons/icons-sprite.svg#close" />
  </svg>
</div>

<mat-dialog-content>
  <div class="mt-5">
    <!-- <app-upload-file [uploadedFiles]="uploadedFiles" [resourceDto]="dto" [conf]="conf"></app-upload-file> -->
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="btn btn-outline-dark" mat-dialog-close>Cancel</button>
  <button class="btn btn-dark" [mat-dialog-close]="true" cdkFocusInitial
    [disabled]="this.uploadFileComponent?.currentAttachments?.length === 0 && this.uploadFileComponent?.fileUploadControl?.value?.length===0"
    (click)="saveDocument()">Add {{conf.type}}</button>
</mat-dialog-actions>
