export const services = {

  /* NOT SECURIZED URLS */
  NOT_SECURIZED: [],

  /** LOGIN & LOGOUT */
  LOGIN_OAUTH_TOKEN: '/users/authentication.json',
  LOGOUT: '/logout',
  IDP_GET_ENTITIES: '/login/idpEntities.json',
  SAML_LOGIN: '/./saml/login?disco=true&idp=',

  /** USER */
  CURRENT_USER: '/users/currentUser.json',
  CURRENT_USER_PROFILE: '/users/currentUserProfile.json',
  USER_PROFILE_ADMIN: '/userProfileService',
  LOGIN_EXISTS: '/user/checkLogin',
  USER_EXISTS: '/user/existUser',

  /**GENERIC GLOBAL RESOURCES */
  /**TO DO JSONS */
  GLOBAL_RESOURCES: '/genericGlobalResources',

  GLOBAL_RESOURCES_RELATED: '/genericGlobalResources/{globalResourceGroupId}/related',
  RELATED_WITHOUT_RELATED: '/genericGlobalResources/relatedgates.json',
  //GLOBAL_RESOURCES_ID_VERSIONS:           '/genericResources/{globalResourceGroupId}/versions',
  //GLOBAL_RESOURCES_ID_VERSIONS_ID:        '/genericGlobalResources/{globalResourceGroupId}/versions/{versionNumber}',


  TREE_METADATA_ONE_LEVEL_BY_ID: '/metadataService/treeMetadataOneLevelByMetadata_',
  //Project
  //GET_CHILD_RELATED_INFORMATION:          '/projectService/getProjectChildRelatedInformation.json',
  GET_PROJECT_RELATED_STAGES: '/projectService/getProjectStageRelatedInformation.json',
  GET_PROJECT_ACTUAL_STAGE_ADDITIONAL_INFO: '/projectService/getAdditionalInfoInActualStageGateInProject.json',

  //Gate
  MASTER_STAGE_GATES: '/gateService/masterStageGates.json',
  MASTER_STAGE_STEEPS: '/stepsService/masterStageSteeps.json',

  INIT_PROJECT_RELATED_DTOS: '/initProjectRelatedDtos',
  SAVE_PROJECT_AND_GATES_DTO: '/saveProjectAndGatesDto',
  USER_PROFILE_TO_ADD_IN_GATES: '/users/profilesToAddInGates.json',
  ACTIVATE_PROJECT: '/activateProject',

  /* MASTER DATA */
  MASTER_DATA_ANALYTICS: '/masterData/analytics.json',

  /* ATTACHMENTS */
  ATTACHMENT_GET_PREVIEW: '/attachment/preview/{contentId}/{type}',

  /** MICROSOFT PHOTO */
  MS_PHOTO: '/msPhoto?mail=',

  /** MASTER  */

  MASTER_TABLES_ITEM_ACTIVE: '/mt/updateActive',
  MASTER_TABLES_AGENCIES_INFORMATIVE: '/mt/agenciesInformative',
  MASTER_TABLES_COUNTRIES: '/mt/countries',
  MASTER_TABLES_ALL_SECTIONS_LIST: '/mt/sectionsList.json',
  MASTER_TABLES_SECTION_INFORMATION: '/mt/sectionInformation',
  MASTER_TABLES_LIST: '/mt/list',
  MASTER_TABLES_ADD: '/mt/add',
  MASTER_TABLES_USER_COUNTRIES: '/mt/countries',
  MASTER_TABLES_USER_COUNTRIES_ACTIVE: '/mt/countriesActive',

  /* MASTER CONFIGURATION */
  MASTER_CONFIGURATION: '/masterData/{type}/{component}/{parameter}',
  
  /** TEMPLATE PERMS */
  TEMPLATE_PERM: '/templatePerm',
  TEMPLATE_PERM_TREE: '/templatePerm/getTree',
  TEMPLATE_PERM_TREE_BY_GROUP: '/templatePerm/getTreeByGroup',

    /** CONTACTS */
    CONTACT_LIST:                            '',

};
