import { EVENT_STATUS } from "../shared/enums/event-status.enum";
import { GlobalConstants } from '../constants/global.constants';

export interface MyEventResponse {
    action: string[],
    address: string[],
    brandSegment: string[],
    creationDate: number,
    creationUser: { userId: number, globalResourceGroupId: number, fullName: string },
    description: string[],
    dueDate: number[],
    endDate: number[],
    event: string[],
    fav: boolean,
    forLater: boolean,
    globalResourceGroupId: number,
    globalResourceId: string,
    globalResourceTypeId: number,
    lastVersion: number,
    name: string[],
    permission: number,
    praparationStatus: string[],
    scale: string[],
    startDate: number[],
    subscription: boolean,
    typology: string[],
    versionNumber: number,
    market?: any,
    havasTeam?: any,
    clientTeam?: any
    client?: any
}

export interface MyEvent {
    id: number,
    globalResourceId: string,
    globalResourceTypeId: number,
    creationDate: number,
    creationUser: {
        userId: number,
        globalResourceGroupId: number,
        fullName: string
    },
    globalResourceGroupId: number,
    versionNumber: number,
    lastVersion: number,
    task: number[],
    status: number,
    title: string[],
    eventDate: number[],
    dueDate: number[],
    endDate: number[],
    scale: string[],
    market: string[],
    brand: string[],
    eventType: string[],
    havasTeam: number[],
    clientTeam: number[],
    client: string[],
    fav: boolean,
    forLater: boolean,
    subscription: boolean,
    permission: number,
    description: string,
    address: string,
    actionType: string,
}

export class MyEvent implements MyEvent {
    constructor(event?: MyEventResponse) {
        this.id = event?.globalResourceGroupId || 0
        this.creationDate = event?.creationDate || new Date().getTime();
        this.creationUser = event?.creationUser || { userId: 0, globalResourceGroupId: 0, fullName: '' };
        this.eventDate = event?.startDate || [];
        this.dueDate = event?.dueDate || [];
        this.endDate = event?.endDate || [];
        this.eventType = event?.typology || [];
        this.fav = event?.fav || false;
        this.forLater = event?.forLater || false;
        this.globalResourceGroupId = event?.globalResourceGroupId || 0;
        this.globalResourceId = event?.globalResourceId || '';
        this.globalResourceTypeId = event?.globalResourceTypeId || GlobalConstants.EVENTS.TYPE;
        this.title = event?.name || [];
        this.permission = event?.permission || 0;
        this.status = event?.praparationStatus ? this.getStatus((event?.praparationStatus[0] || '').toLowerCase()) : 0;
        this.scale = event?.scale || [];
        this.subscription = event?.subscription || false;
        this.market = event?.market || [];
        this.brand = event?.brandSegment || [];
        this.havasTeam = event?.havasTeam || [];
        this.clientTeam = event?.clientTeam || [];
        this.client = event?.client || [];
        this.description = (event && event.description) ? event.description[0] : '';
        this.address = (event && event.address) ? event.address[0] : '';
        this.actionType = event?.action.join(', ') || '';
    }

    private getStatus(status: string) {
        switch (status) {
            case EVENT_STATUS.COMPLETE:
                return 1;
            case EVENT_STATUS.SCHEDULED:
                return 2;
            case EVENT_STATUS.ARCHIVED:
                return 3;
            case EVENT_STATUS.PREPARING:
                return 4;
            default:
                return 0;
        }
    }
}
