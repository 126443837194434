<div class="mat-dialog-header">
  <h3 mat-dialog-title>Send for approval</h3>
  <svg class="icon ic-20" mat-dialog-close><use href="assets/icons/icons-sprite.svg#close"/></svg>
</div>

<mat-dialog-content>

  <div class="mb-2">
    <h6 class="mb-3">Onboard someone</h6>
    <mat-form-field class="example-chip-list">
      <mat-label>Name or email</mat-label>
      <mat-chip-list #chipList aria-label="Onboard selection">
        <mat-chip
          *ngFor="let user of users"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(user)">
          <div app-avatar imgSrc="assets/img/avatar.jpg" type="circle"></div>
          {{user}}
          <button matChipRemove *ngIf="removable">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="New user..."
          #userInput
          [formControl]="userCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete class="mat-option-avatar" classList="mat-option-avatar" #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          <div app-avatar imgSrc="assets/img/avatar-02.jpg" size="sm" type="circle"></div>
          <span class="text-truncate">{{user}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>


  <app-upload-file [resourceDto]="data"></app-upload-file>

  <div class="mb-4">
    <h6 class="mb-3">Internal Meeting completed?</h6>
    <mat-checkbox required [(ngModel)]="internalMeeting" color="primary">Mark as done</mat-checkbox>
  </div>

  <div class="mb-4">
    <h6 class="mb-3">Client Meeting completed?</h6>
    <mat-checkbox required [(ngModel)]="clientMeeting" color="primary">Mark as done</mat-checkbox>
  </div>


<app-dialog-message></app-dialog-message>

</mat-dialog-content>

<app-dialog-save-buttons [isDisabled]="!internalMeeting || !clientMeeting" (saveDocumentEvent)="saveDocument()"></app-dialog-save-buttons>
