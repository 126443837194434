import { EventConstants } from "../constants/event.constants";
import { MonthConstants } from "../constants/month.constants";
import { EventContact } from "./event-contact.model";
import { EventForm } from "./event-form.model";
import { TaskDto } from "./task-dto.model";
import { EventTask } from "./event-task.model";

export interface EventDto {
    name: string,
    address: string,
    startDate: Date,
    endDate: Date,
    dueDate: Date,
    description: string,
    clientMetadataContentId: number,
    actionMetadataContentId: number,
    marketMetadataContentId: number[],
    scaleMetadataContentId: number,
    brandSegmentMetadataContentId: number[],
    typologyMetadataContentId: number,
    attendingClientMetadataContentId: number[],
    attendingHavasMetadataContentId: number[],
    ownerMetadataContentId: number[],
    praparationStatusMetadataContentId: number[],
    monthMetadataContentId: number[],
    task: TaskDto[];
}

export class EventDto implements EventDto {
    constructor(event: EventForm, clientId: number, havasTeamList: EventContact[], ownerContactList: EventContact[], clientTeamAttendingList: EventContact[], task: EventTask[]) {
        this.name = (event.name || '').trim();
        this.address = (event.address || '').trim();
        this.startDate = this.processDateBeforeSave(new Date(event.startDate));
        this.endDate = this.processDateBeforeSave(new Date(event.endDate));
        this.dueDate = this.processDateBeforeSave(new Date(event.dueDate));
        this.description = (event.description || '').trim();
        this.clientMetadataContentId = clientId;
        this.actionMetadataContentId = event.actionType;
        this.marketMetadataContentId = [event.market];
        this.scaleMetadataContentId = event.scale;
        this.brandSegmentMetadataContentId = event.brand || [];
        this.typologyMetadataContentId = event.typology;
        this.attendingClientMetadataContentId = event.attendingClient || [];
        this.attendingHavasMetadataContentId = event.attendingHavas || [];
        this.ownerMetadataContentId = event.owner || [];
        this.praparationStatusMetadataContentId = [EventConstants.MC.PREPARATION_STATUS_EVENT_CREATED_ID]; // Valor por defecto 'Event created'
        this.monthMetadataContentId = this.getMonthMetadataId(this.startDate);
        // this.attendingClientMetadataContentId = clientTeamAttendingList.map(i => i.id); // TODO: Revisar Lista de contactos
        // this.attendingHavasMetadataContentId = havasTeamList.map(i => i.id); // TODO: Revisar Lista de contactos
        // this.ownerMetadataContentId = ownerContactList.map(i => i.id); // TODO: Revisar Lista de contactos
        this.task = task && task.length ? task.map(t => new TaskDto(t)) : [];
    }

    private processDateBeforeSave(date: Date): Date {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }

    private getMonthMetadataId(date: Date): number[] {
        return date ? [MonthConstants.MC.MONTHS_ARRAY_IDS[new Date(date).getMonth()]] : [0];
    }

}

