<section class="l-login">
    <div class="row g-0 l-login-wrapper">
        <div class="col-lg-4 col-xl-3 l-login-left">
            <div class="l-login__bg">
                <img class="object-cover" src="assets/img/bg.login.jpg" alt="{{ 'APP.TEXTS.WELCOME_TO' | translate }} {{ 'APP.TEXTS..NAME' | translate }}!">
            </div>
            <div class="l-login__brand">
                <img src="assets/img/brand.dark.svg" alt="Client Engagement Assistant">
            </div>
            <div class="l-login-left__cnt">
                <h1 class="mat-display-3">{{ 'APP.TEXTS.WELCOME_TO' | translate }}<br>{{ 'APP.TEXTS.NAME' | translate }}!</h1>
            </div>
            <div class="l-login__logo">
                <img src="assets/img/havasmedia.white.svg" alt="Havas media">
            </div>
        </div>
        <div class="col-lg-8 col-xl-9 l-login-right">
            <div class="l-login-right__admin">
                <div *ngIf="!isAdminAccess">{{ 'APP.TEXTS.LOGIN.ARE_ADMIN' | translate }}<button class="btn btn-link" (click)="isAdminAccess = !isAdminAccess">{{ 'APP.TEXTS.LOGIN.SIGN_IN' | translate }}</button></div>
                <div *ngIf="isAdminAccess">{{ 'APP.TEXTS.LOGIN.NOT_ADMIN' | translate }}<button class="btn btn-link" (click)="isAdminAccess = !isAdminAccess">{{ 'APP.TEXTS.LOGIN.BACK_LOGIN' | translate }}</button></div>
            </div>
            <div class="col l-login__form">
                <div *ngIf="isAdminAccess" class="l-login-right__subtitle">{{ 'APP.TEXTS.LOGIN.ADMIN' | translate }}</div>
                <h2>{{ 'APP.TEXTS.LOGIN.SIGN_IN_CEA' | translate }}</h2>
                <p>{{ 'APP.TEXTS.LOGIN.WELCOME_TEXT' | translate }}</p>
                 <!-- Havas login -->
                <div *ngIf="!isAdminAccess" class="c-login-access-havas">
                  <a (click)="idps? this.havasLogin(idps[0]) : undefined;" class="c-havas-login">
                      <span class="c-havas-login-iso"></span>
                      <span class="c-havas-login-logo">
                          <svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#logo-havas-login"></use></svg>
                      </span>
                  </a>
                  <small class="text-gray">{{ 'APP.TEXTS.LOGIN.PROBLEM_LOGIN_TEXT' | translate }}<a class="text-gray" href="mailto:cea.support@havasmg.com">cea.support@havasmg.com</a></small>
                </div>
                <!-- Havas admin -->
                <div *ngIf="isAdminAccess" class="c-login-access-admin">
                    <form (ngSubmit)="login(f)" #f="ngForm">
                        <mat-form-field class="mat-form-field__lg">
                            <mat-label>{{ 'APP.TEXTS.LOGIN.USERNAME' | translate }}</mat-label>
                            <input matInput id="loginUsername" name="loginUsername" [(ngModel)]="userLogin.loginUsername" placeholder="{{ 'APP.TEXTS.LOGIN.PH_USERNAME' | translate }}">
                        </mat-form-field>
                        <mat-form-field class="mat-form-field__lg mat-form-field__icon-right">
                            <mat-label>{{ 'APP.TEXTS.LOGIN.PASSWORD' | translate }}</mat-label>
                            <input matInput [type]="viewPass ? 'text' : 'password'" id="loginPassword" name="loginPassword" [(ngModel)]="userLogin.loginPassword" placeholder="{{ 'APP.TEXTS.LOGIN.PH_PASSWORD' | translate }}" require>
                            <a href="javascript:void(0)" class="btn btn-icon btn-icon-only" (click)="viewPass = !viewPass">
                                <svg *ngIf="!viewPass" class="icon ic-20"><use href="assets/icons/icons-sprite.svg#hide"/></svg>
                                <svg *ngIf="viewPass" class="icon ic-20"><use href="assets/icons/icons-sprite.svg#show"/></svg>
                            </a>
                        </mat-form-field>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-lg btn-dark">{{ 'APP.TEXTS.LOGIN.LOGIN' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="l-login-footer"></div>
</section>
