<div class="mb-4">
  <h6 class="mb-3" *ngIf="!conf || conf.showTitle">Add {{label}}</h6>
  <!-- <file-upload [control]="fileUploadControl" [multiple]="multiple" [animation]="animation"></file-upload> -->
  <file-upload [control]="fileUploadControl" [multiple]="multiple" [animation]="animation">
    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
        <svg class="icon ic-24"><use href="assets/icons/icons-sprite.svg#upload"/></svg>
        <span><strong>Drag and drop</strong> files or click here</span>
    </ng-template>

    <ng-template let-i="index" let-file="file" let-control="control" #item>
      <div class="c-document">
        <div appDataLabelGroup>
          <div app-avatar></div>
          <div appDataLabel size="sm">
            <span appDataLabelCaption>{{label}}</span>
            <a [routerLink]=""appDataLabelText>{{ file.name }}</a>
          </div>
        </div>
        <div class="c-document-actions">
          <button class="btn btn-outline-danger btn-icon btn-icon-only" (click)="control.removeFile(file)">
            <svg class="icon ic-16">
              <use href="assets/icons/icons-sprite.svg#delete"/>
            </svg>
          </button>
        </div>
      </div>
    </ng-template>
  </file-upload>

  <!-- CURRENT FILES -->
  <div class="d-flex align-items-center justify-content-between" *ngFor="let file of currentAttachments; ; let i = index">
    <div appDataLabelGroup>
      <div app-avatar></div>
      <div appDataLabel size="sm">
        <span appDataLabelCaption>{{label}}</span>
        <a [routerLink]=""appDataLabelText>{{file.name}}</a>
      </div>
    </div>
    <button type="button" class="btn text-danger btn-icon-only" (click)="removeCurrentFile(i)"><svg class="icon ic-16"><use href="assets/icons/icons-sprite.svg#delete"/></svg></button>
  </div>
</div>
