import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsService} from 'cs';
import {NavigationService} from './services/navigation.service';
import {Languages} from './shared/enums/languages.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private navigationService: NavigationService,
  ) {
    // this.translate.use( this.getNormalizedLanguage(this.translate.getBrowserCultureLang()) ); Usar idioma del navegador
    this.translate.use(Languages.ENGLISH);
  }

  ngOnInit() {
    this.analyticsService.auditLocation();
    this.navigationService.init();
  }

  getNormalizedLanguage(language: string | undefined): string {
    return language ? (
      language.includes('es') ?
        Languages.SPANISH :
        (language.includes('fr') ?
          Languages.FRENCH :
          Languages.ENGLISH)
    ) : Languages.ENGLISH;
  }


}
