<div class="c-sidenav-menu__header">
  <svg class="icon ic-20" mat-dialog-close><use href="assets/icons/icons-sprite.svg#close"/></svg>
</div>
<mat-dialog-content class="c-sidenav-menu__body">

  <nav class="c-sidenav-menu__nav">
    <ul>
      <li><a [routerLink]="['/events']" routerLinkActive="active">Events</a></li>
      <li><a [routerLink]="['/contacts']" routerLinkActive="active">Contacts</a></li>
    </ul>
  </nav>

</mat-dialog-content>
