export class UserLoginModel {

  loginUsername: string;
  loginPassword: string;

  constructor() {
    this.loginUsername = '';
    this.loginPassword = '';
  }

}
