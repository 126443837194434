import { Injectable } from '@angular/core';
import { CoreService } from 'node_modules/cs'
import { environment } from '@environment';
import { services } from '@services';

@Injectable()
export class AppInitService {

  constructor(private coreService: CoreService) { }

  init() {

    return new Promise<void>((resolve, reject) => {
      console.log("AppInitService.init() called");

      const config = new Map<String, any>();
      config.set("environment", environment);
      config.set("services", services);

      this.coreService.init(config);

      setTimeout(() => {
        console.log('AppInitService Finished');
        resolve();
      }, 1000);

    });

  }
}
