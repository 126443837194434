import { Component, Input, OnInit } from '@angular/core';
import { MyEvent } from 'src/app/models/event-my.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input()
  event: MyEvent = new MyEvent();

  @Input()
  showDetails: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
