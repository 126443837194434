import { MyEvent } from "./event-my.model";

export interface EventForm {
    name: string,
    address: string,
    typology: number,
    actionType: number,
    market: number,
    scale: number,
    brand: number[],
    startDate: string,
    endDate: string,
    dueDate: string,
    description: '',
    attendingClient: number[],
    attendingHavas: number[],
    owner: number[]
}

export class EventForm implements EventForm {
    constructor(event?: MyEvent) {
        this.name = '';
        this.address = '';
        this.typology = 0;
        this.actionType = 0;
        this.market = 0;
        this.scale = 0;
        this.brand = [];
        this.startDate = '';
        this.endDate = '';
        this.dueDate = '';
        this.description = '';
        this.attendingClient = [];
        this.attendingHavas = [];
        this.owner = [];
    }
}