<div class="mat-dialog-header">
  <h3 mat-dialog-title>{{(enabledEdit ? 'edit event' : 'add event') | titlecase }}</h3>
  <svg class="icon ic-20" mat-dialog-close>
    <use href="assets/icons/icons-sprite.svg#close"/>
  </svg>
</div>

<mat-dialog-content>

  <form #eventForm="ngForm">

    <h6 class="mb-3">{{'event overview' | titlecase }}</h6>
    <div class="row gy-2 mb-4">

      <div class="col-12">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'event name' | titlecase }}</mat-label>
          <input #name matInput name="name" appWhitespacesValidator [(ngModel)]="event.name" maxlength="250" required>
          <mat-hint align="end">{{name.value.length}} / 250</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'scale' | titlecase }}</mat-label>
          <mat-select name="scale" [(ngModel)]="event.scale" required>
            <mat-option *ngFor="let item of byScaleList; trackBy: trackById" [value]="item.metadataContentId">
              {{item.metadataContentText}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'market(s)' | titlecase }}</mat-label>
          <mat-select name="market" [(ngModel)]="event.market" required>
            <mat-option *ngFor="let item of byMarketList; trackBy: trackById" [value]="item.metadataContentId">
              {{item.metadataContentText}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>Address</mat-label>
          <textarea name="address" #address matInput [(ngModel)]="event.address" maxlength="250" cdkTextareaAutosize
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="4"></textarea>
          <mat-hint align="end">{{address.value.length}} / 250</mat-hint>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'event typology' | titlecase }}</mat-label>
          <mat-select name="typology" [(ngModel)]="event.typology" required>
            <mat-option *ngFor="let item of byTypologyList; trackBy: trackById" [value]="item.metadataContentId">
              {{item.metadataContentText}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'action type' | titlecase }}</mat-label>
          <mat-select name="actionType" [(ngModel)]="event.actionType" required>
            <mat-option *ngFor="let item of byActionTypeList; trackBy: trackById" [value]="item.metadataContentId">
              {{item.metadataContentText}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'Brand/Segment'}}</mat-label>
          <mat-select name="brand" [(ngModel)]="event.brand" multiple required>
            <mat-option *ngFor="let item of byBrandList; trackBy: trackById" [value]="item.metadataContentId">
              {{item.metadataContentText}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>Start and End dates</mat-label>
          <mat-date-range-input [rangePicker]="picker" [min]="currentDate" required>
            <input matStartDate placeholder="Start date" name="startDate" [(ngModel)]="event.startDate">
            <input matEndDate placeholder="End date" name="endDate" [(ngModel)]="event.endDate">
          </mat-date-range-input>
          <mat-datepicker-toggle class="mat-datepicker-toggle__custom-icon" matSuffix [for]="picker">
            <svg matDatepickerToggleIcon class="icon ic-20">
              <use href="assets/icons/icons-sprite.svg#calendar"/>
            </svg>
          </mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'due date' | titlecase }}</mat-label>
          <input matInput [matDatepicker]="dueDatePicker" name="dueDate" [(ngModel)]="event.dueDate" required>
          <mat-datepicker-toggle class="mat-datepicker-toggle__custom-icon" matSuffix [for]="dueDatePicker">
            <svg matDatepickerToggleIcon class="icon ic-20">
              <use href="assets/icons/icons-sprite.svg#calendar"/>
            </svg>
          </mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field class="mat-form-field__lg">
          <mat-label>{{'event description' | titlecase }}</mat-label>
          <textarea #description matInput placeholder="Tells all the essential details about the event..."
                    maxlength="1000" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6"
                    name="description"
                    [(ngModel)]="event.description" appWhitespacesValidator required></textarea>
          <mat-hint align="end">{{description.value.length}} / 1000</mat-hint>
        </mat-form-field>
      </div>

    </div>
    <div demoHidden>
      <p class="mb-3" required>Owner</p>
      <div class="mb-4">
        <app-add-repeat [contactList]="havasTeamList" [isRequired]="true"
                        (callBackEvent)="addOwner($event)"></app-add-repeat>
      </div>

      <p class="mb-3">Havas Team Assigned</p>
      <div class="mb-4">
        <app-add-repeat [contactList]="havasTeamList" [isRequired]="true"
                        (callBackEvent)="addHavasTeam($event)"></app-add-repeat>
      </div>

      <p class="mb-3">Client team Attending</p>
      <div class="mb-4" required>
        <app-add-repeat [contactList]="owenrContactList" [isRequired]="true"
                        (callBackEvent)="addClientTeamAttending($event)"></app-add-repeat>
      </div>

      <h6 class="mb-3">Tasks</h6>
      <div class="mb-4">
        <app-forms-tasks (callBackEvent)="addTask($event)"></app-forms-tasks>
      </div>
    </div>

  </form>

</mat-dialog-content>

<app-dialog-save-buttons [isDisabled]="!(eventForm.form.valid && ( isValidFormContat || APP.IS_DEMO))" [enabledDelete]="enabledEdit"
                         (saveDocumentEvent)="save()" (deleteDocumentEvent)="delete()">
</app-dialog-save-buttons>
