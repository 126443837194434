
import { EventTask } from "./event-task.model";

export interface TaskDto {
    description: string,
    done: boolean
}

export class TaskDto implements TaskDto {
    constructor(task: EventTask) {
        this.description = task.description;
        this.done = task.done;
    }
}