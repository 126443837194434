import {AfterViewInit, Directive, ElementRef, Renderer2} from '@angular/core';
import {AppService} from '../../services/app.service';

@Directive({
  selector: '[demoHidden]'
})
export class DemoHiddenDirective implements AfterViewInit {


  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private _AppService: AppService,
  ) {
  }

  ngAfterViewInit(): void {
    this._AppService.isDemo$.subscribe((res: any) => {
      if (res) {
        this.renderer.removeChild(this.renderer.parentNode(this.elRef.nativeElement), this.elRef.nativeElement)
      }
    });
  }
}
