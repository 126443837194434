<header class="header">
  <button role="button" (click)="openDialogMenu()" class="header__ham btn btn-primary btn-icon btn-icon-only">
    <svg class="icon">
      <use href="assets/icons/icons-sprite.svg#menu"/>
    </svg>
  </button>
  <a class="header__brand" [routerLink]="['/']">
    <img src="assets/img/brand.dark.svg" alt="">
  </a>
  <nav class="header__nav">
    <ul>
      <li><a [routerLink]="['/events']" routerLinkActive="active">Events</a></li>
      <li demoHidden><a [routerLink]="['/contacts']" routerLinkActive="active">Contacts</a></li>
    </ul>
  </nav>

  <div class="header__right">

    <div class="header__search">
      <mat-form-field class="mat-form-field__dark">
        <mat-label>Client</mat-label>
        <mat-select [(ngModel)]="clientService.itemSelected">
          <mat-option
            *ngFor="let item of clientService.items"
            [value]="item"
          >{{item.metadataContentText}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="header__actions">
      <button role="button" (click)="openDialogSupport()" class="btn btn-primary btn-icon btn-icon-only"
              matTooltip="Support" [matTooltipShowDelay]="1000">
        <svg class="icon">
          <use href="assets/icons/icons-sprite.svg#help"/>
        </svg>
      </button>
        <button demoHidden role="button" class="header__actions__admin btn btn-primary btn-icon btn-icon-only"
                [routerLink]="['/admin']">
          <svg class="icon">
            <use href="assets/icons/icons-sprite.svg#setting"/>
          </svg>
        </button>
      <button role="button" (click)="logout()" class="btn btn-primary btn-icon btn-icon-only" matTooltip="Sign Out"
              [matTooltipShowDelay]="1000">
        <svg class="icon">
          <use href="assets/icons/icons-sprite.svg#logout"/>
        </svg>
      </button>
    </div>

    <!-- <div>
      <button app-avatar imgSrc="assets/img/avatar.jpg" type="circle" theme="dark" [matMenuTriggerFor]="beforeMenu"></button>
      <mat-menu #beforeMenu="matMenu" xPosition="before" class="c-menu-profile">
        <div class="c-menu-profile-header">
          <div appDataLabel size="sm">
            <span appDataLabelCaption>Managing Director</span>
            <span appDataLabelText>Austin C. Inman</span>
          </div>
        </div>
        <div class="c-menu-profile-cnt">
          <button mat-menu-item router>Edit Profile</button>
          <button mat-menu-item>Setting</button>
          <button mat-menu-item [routerLink]="['/admin']" routerLinkActive="active">Admin</button>
        </div>
        <div class="c-menu-profile-footer">
          <button mat-menu-item class="btn-logout" (click) ="logout()">
            <span>Sign Out</span>
            <svg class="icon ic-16"><use href="assets/icons/icons-sprite.svg#logout"/></svg>
          </button>
        </div>
      </mat-menu>
    </div> -->

  </div>

</header>

