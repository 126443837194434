import {UserConstants} from './user.constants';
import {EventConstants} from './event.constants';
import {ClientConstants} from './client.constants';
import {MonthConstants} from './month.constants';
import { MasterConstants } from './master.constants';

export const GlobalConstants: any = {
  USERS: UserConstants,
  MASTER: MasterConstants,
  EVENTS: EventConstants,
  CLIENT: ClientConstants,
  MONTH: MonthConstants,
  IS_DEMO: undefined,
};
