import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environment';
import { services } from '@services';
import { UtilsService } from 'cs';


@Directive({
  selector: '[appBackgroundImage]'
})
export class BackgroundImageDirective implements OnInit, OnChanges {

  @Input() contentId: number = 0;

  @Input() previewType: string = '';

  @Input() imgType: string = '';

  @Input() imgSrc: string = '';

  @Input() mail: string = '';


  timesToCheckUrl = 5;
  timesUrlChecked = 0;
  hasThumbnail = false;

  types: any = {
    'LOGO': { defaultImg: 'assets/img/brand.dark.svg', previewType: 'SMALL_IMAGE', theme: 'dark' },
    'IMAGE': { defaultImg: 'assets/img/default-campaign-image.jpg', previewType: 'LARGE_IMAGE' },
    'DEFAULT': { previewType: 'SMALL_IMAGE', defaultImg: 'assets/img/default-campaign-image.jpg' },
  }


  constructor(private el: ElementRef, private renderer: Renderer2, private utilsService: UtilsService, private sanitizer: DomSanitizer,
    private http: HttpClient) { }

  ngOnInit(): void { }


  ngOnChanges(changes: SimpleChanges): void {
    this.hasThumbnail = false;
    if (this.mail) {
      this.setSrc(this.mail);
    }
    else {
      if (changes.contentId?.currentValue) {
        this.thumbnail();
      } else if (this.imgSrc && this.imgSrc != '') {
        this.setSrc(this.imgSrc);
      } else if (this.imgType === 'LOGO' || this.imgType === 'IMAGE') {
        this.setSrc(this.types[this.imgType].defaultImg);
      }
    }

  }


  thumbnail = () => {
    let imageType = this.imgType ? this.imgType : 'DEFAULT';
    const url = `${environment.apiEndpoint}${services.ATTACHMENT_GET_PREVIEW}`
      .replace('{contentId}', this.contentId.toString())
      .replace('{type}', this.previewType ? this.previewType : this.types[imageType].previewType);

    if (this.timesUrlChecked < this.timesToCheckUrl && !this.hasThumbnail) {
      this.timesUrlChecked++;
      this.utilsService.getContentConcurrent(url, false).subscribe(
        response => {
          if (response != '') {
            this.hasThumbnail = true;
            this.setSrc(response);
          } else {
            this.setSrc(this.types[imageType].defaultImg);
            setTimeout(() => this.thumbnail(), 3000);
          }
        },
        err => {
          this.setSrc(this.types[imageType].defaultImg);
        });
    }
  }
  private setSrc(src: any) {
    this.renderer.setAttribute(this.el.nativeElement, 'src', src);
  }

}
