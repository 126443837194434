import { Component, OnInit, Input} from '@angular/core';
import { FileUploadControl } from '@iplab/ngx-file-upload';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  @Input() resourceDto:any;
  @Input() conf: any;
  @Input() uploadedFiles: any;

  //@Output() eventEmitter:any = new EventEmitter();
  public animation: boolean = false;
  public multiple: boolean = false;
  public fileUploadControl = new FileUploadControl();
  currentAttachments:any[] = [];
  label : any = 'Document';

  private deletedAttachents: any[] = [];

  constructor() {

   }

  ngOnInit(): void {
    this.label = (this.conf && this.conf.type) ? this.conf.type : 'Document';
    this.fileUploadControl.valueChanges.subscribe(file => {
      if(this.multiple == false){
        this.deletedAttachents.push(...this.currentAttachments);
        this.currentAttachments = [];
      }
    })
    if(this.resourceDto?.file?.currentAttachments){
      let attachments = this.resourceDto.file.currentAttachments;
      if( this.conf && this.conf.fileId) {
        attachments = attachments.filter((file:any) => file.fileAttachmentType=== this.conf.fileId);
      }
      this.currentAttachments =  [... attachments];
    }
    if(this.conf && this.conf.accept){
      this.fileUploadControl.acceptFiles(this.conf.accept);
    }
    if(this.conf && this.conf.multiple){
      this.multiple = true;
      this.fileUploadControl.multiple(true);
    }
    if(this.uploadedFiles && this.uploadedFiles.length !== 0) {
      this.uploadedFiles.forEach((file : any) => {
        if(file.file){
          this.fileUploadControl.addFiles(file.file);
        }
      });

    }
  }


  returnFilesDocument(){
    let files: any = {};

    files.newAttachments = [];

    const fileId = this.conf && this.conf.fileId ? this.conf.fileId : 'file';
    if(this.fileUploadControl.value && this.fileUploadControl.value.length>0){
      files.newAttachments.push({
        id: fileId,
        file: this.fileUploadControl.value
      });
    }
    if(this.currentAttachments && this.currentAttachments.length>0){
      files.contentIdMetadataContentId = this.currentAttachments.map(attachment => attachment.contentIdMetadataContentId);
    }
    files.deletedAttachents = this.deletedAttachents;
    return files;
  }

  removeCurrentFile(index: number){
    let splicedValue = this.currentAttachments.splice(index, 1);
    this.deletedAttachents.push(...splicedValue);
  }

}
