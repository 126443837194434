import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {GlobalConstants} from '../constants/global.constants';
import {MasterConfigurationService} from 'cs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public isDemo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _MasterConfigurationService: MasterConfigurationService,
  ) {
  }

  public setup(): void {
    this.setDemo();
  }

  private setDemo(): void {
    if (GlobalConstants.IS_DEMO === undefined) {
      this._MasterConfigurationService.getOneMasterConfiguration('app', 'is', 'demo').subscribe(
        (res: any) => {
          GlobalConstants.IS_DEMO = (
            res &&
            res.value &&
            res.value === '1'
          );
          if (GlobalConstants.IS_DEMO) {
            this.isDemo$.next(GlobalConstants.IS_DEMO);
          }
        }
      )
    }
  }
}
