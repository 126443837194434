import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { services } from '@services';
import { HttpService, MetadataSearchDto } from 'node_modules/cs';
import { environment } from '@environment';
import { EventConstants } from '../constants/event.constants';
import { ContactsConstants } from '../constants/contacts.constants';

@Injectable({
  providedIn: 'root'
})
export class GenericGlobalService {

  constructor(private httpService: HttpService) {
  }


  getAll(searchDto: MetadataSearchDto, fromScroll: boolean): Observable<any> {
    searchDto.pagination!.page = fromScroll ? searchDto.pagination!.page + 1 : 1;
    let url = services.GLOBAL_RESOURCES;
    if (environment.offline) {
      if (searchDto.globalResourceTypeId === EventConstants.TYPE) {
        url += `/${searchDto.globalResourceTypeId}/${searchDto.globalResourceTypeId}_List.json`;
      }
      if (searchDto.globalResourceTypeId === ContactsConstants.TYPE) {
        url += `/${searchDto.globalResourceTypeId}/${searchDto.globalResourceTypeId}_List.json`;
      }
    }
    return this.httpService.getAll(url, searchDto);
  }

  // Only prepare for Project
  getOne(globalResourceGroupId: number, globalResourceTypeId?: any) {
    let url = services.GLOBAL_RESOURCES;
    if (environment.offline) {
      url += `/${globalResourceTypeId}/${globalResourceGroupId}.json`;
    }
    return this.httpService.getOne(url, globalResourceGroupId, globalResourceTypeId);
  }

  // Only prepare for Project
  getUrl(url: string) {
    return this.httpService.getUrl(url);
  }

  save(dto: any, globalResourceTypeId: number, files?: any) {
    let url = services.GLOBAL_RESOURCES;
    if (environment.offline) {
      let globalResourceId = dto.globalResourceId ? dto.globalResourceId : 0;
      url += `/${globalResourceTypeId}/${globalResourceId}.json`;
    }
    return this.httpService.save(url, dto, globalResourceTypeId, files);
  }


  delete(globalResourceGroupId: number, globalResourceTypeId: number) {
    return this.httpService.delete(services.GLOBAL_RESOURCES, globalResourceGroupId, globalResourceTypeId);
  }
}
