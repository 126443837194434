import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddDocumentComponent } from '../shared/components/dialogs/dialog-add-document/dialog-add-document.component';
import { DialogAddEventComponent } from '../shared/components/dialogs/dialog-add-event/dialog-add-event.component';
import { DialogSendApprovalComponent } from '../shared/components/dialogs/dialog-send-approval/dialog-send-approval.component';
import { DialogGenericComponent } from '../shared/components/dialogs/dialog-generic/dialog-generic.component';
import { DialogAddImageComponent } from '../shared/components/dialogs/dialog-add-image/dialog-add-image.component';
import { DialogAlertComponent } from '../shared/components/dialogs/dialog-alert/dialog-alert.component';
import { DialogSimpleAddDocumentComponent } from '../shared/components/dialogs/dialog-simple-add-document/dialog-simple-add-document.component';
import { DialogSupportComponent } from '../shared/components/dialogs/dialog-support/dialog-support.component';
import { DialogMenuComponent } from '../shared/components/dialogs/dialog-menu/dialog-menu.component';




@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public showSidebarDetail: boolean;

  private sideBarObservable$ = new Subject<any>();

  sideBarObservable = this.sideBarObservable$.asObservable();

  constructor(public dialog: MatDialog) {
    this.showSidebarDetail = true; // Show Sidebar Detail

  }

  // Show Sidebar Detail

  setShowSidebarDetail(val: boolean) {
    this.showSidebarDetail = val;
    this.sideBarObservable$.next(this.showSidebarDetail);

  }

  getShowSidebarDetail(){
    return this.showSidebarDetail;
  }



  openDialogAddEvent(dialog:MatDialog, data:any, postFunction:any, width: number|undefined) {
    if(!width)width=960;
    const dialogRef = dialog.open(DialogAddEventComponent, {
      width: width + 'px',
      data:  data,
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
        postFunction(result);
      }
    });
  }

  openDialogGenericComponent(dialog:MatDialog, data:any, postFunction:any, width: number|undefined) {
    if(!width)width=575;
    const dialogRef = dialog.open(DialogGenericComponent, {
      width: width + 'px',
      data:  data,
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result){
        postFunction();
      }
    });
  }


  openDialogAddDocument(dialog:MatDialog, data:any,  saveFunction:any, width: number|undefined) {
    if(!width)width=575;
    const dialogRef = dialog.open(DialogAddDocumentComponent, {
      width: width + 'px',
      data:  data ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.files){
        saveFunction(result.files);
      }
    });
  }

  openDialogAddSimpleDocument(dialog:MatDialog, data:any,  saveFunction:any, width: number|undefined) {
    if(!width)width=575;
    const dialogRef = dialog.open(DialogSimpleAddDocumentComponent, {
      width: width + 'px',
      data:  data ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.files){
        saveFunction(result.files);
      }
    });
  }

  openDialogSendApproval(dialog:MatDialog, data:any,  sendApprovalFunction:any, width: number|undefined) {
    if(!width)width=575;
    const dialogRef = dialog.open(DialogSendApprovalComponent, {
      width: width + 'px',
      data:  data ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.files){
        sendApprovalFunction(result.files);
      }
    });
  }

  openDialogSupport(data: any, saveFunction: any, width: number | undefined) {
    if (!width) width = 575;
    const dialogRef = this.dialog.open(DialogSupportComponent, {
      width: width + 'px',
      data: data,
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data && data != 'Cancel') {
        saveFunction(data);
      }
    });
  }


  //Dialog menú, con contact y event
  openDialogMenu() {
    const dialogRef = this.dialog.open(DialogMenuComponent, {
      width: '80vw',
      maxWidth: '20rem',
      height: '100%',
      panelClass: 'c-sidenav-menu',
      position: {left:'0', top: '0'},
      autoFocus: false,
    });
  }

  deleteAttachment(dto: any, filesToDeleted: any ) {
    //let filesToDeleted = files.deletedAttachents.map((element: any) => element.contentIdMetadataContentId);
    dto.file.contentIdMetadataContentId = dto.file.contentIdMetadataContentId.filter(
      (element: any) => !filesToDeleted.includes(element)
    );
    dto.file.currentAttachments = dto.file.currentAttachments.filter(
      (element: any) => !filesToDeleted.includes(element.contentIdMetadataContentId)
    );
  }
}
