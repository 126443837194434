import { Component, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-dialog-menu',
  templateUrl: './dialog-menu.component.html',
  styleUrls: ['./dialog-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogMenuComponent {

  constructor() {}

}
