<div class="c-add-repeat__bar">

  <div class="c-add-repeat__bar__col c-add-repeat__bar__col--user">
    <mat-form-field class="mat-form-field-nofloating">
      <mat-label>Name or email</mat-label>
      <mat-chip-list multiple="false" #chipList aria-label="Onboard selection" [required]="isRequired">
        <mat-chip *ngFor="let user of users; trackBy:trackById" [selectable]="selectable" [removable]="removable"
          (removed)="removeChip(user)">
          <div app-avatar initials="{{user.name | initials}}" size="sm"></div>
          {{user.name | titlecase}}
          <button matChipRemove>
            <svg class="icon ic-12">
              <use href="assets/icons/icons-sprite.svg#close" />
            </svg>
          </button>
        </mat-chip>
        <input placeholder="New user..." #userInput [formControl]="userCtrl" [matAutocomplete]="auto"
          [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-autocomplete class="mat-option-avatar" classList="mat-option-avatar" #auto="matAutocomplete"
        (optionSelected)="selected($event)">
        <cdk-virtual-scroll-viewport [ngStyle]="{'height': height}" itemSize="25">
          <mat-option *cdkVirtualFor="let user of filteredUsers | async" [value]="user">
            <div app-avatar initials="{{user.name | initials}}"></div>
            <span class="text-truncate">{{user.name | titlecase}}</span>
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>

    </mat-form-field>
  </div>

  <div *ngIf="viewSelect" class="c-add-repeat__bar__col c-add-repeat__bar__col--select">
    <mat-form-field>
      <mat-label>Select</mat-label>
      <mat-select [(ngModel)]="byBrandResponsible" multiple [disabled]="!users.length" [required]="isRequired">
        <mat-option *ngFor="let item of byBrandResponsibleList" [value]="byBrandResponsible">{{item}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="c-add-repeat__bar__col c-add-repeat__bar__col--actions">
    <button class="btn btn-icon btn-icon-left btn-primary" [disabled]="!users.length" (click)="addUsers(users)">
      <svg class="icon ic-16">
        <use href="assets/icons/icons-sprite.svg#plus" />
      </svg>Add
    </button>
  </div>

</div>

<div cdkDropList class="c-add-repeat__list" (cdkDropListDropped)="drop($event)">

  <div class="c-add-repeat__list__item" *ngFor="let user of selectedUsers; let i = index; trackBy:trackById">
    <div class="c-card-add-user" cdkDrag>
      <!-- Col -->
      <div class="c-card-add-user__col c-card-add-user__col--user">
        <div appDataLabelGroup>
          <div app-avatar app-avatar initials="{{user.name | initials}}"></div>
          <div appDataLabel size="sm">
            <div class="c-data-label__block">
              <span class="badge badge--havas rounded-pill badge--xs">Havas</span>
            </div>
            <span appDataLabelText>{{user.name | titlecase}}</span>
          </div>
        </div>
      </div>
      <!-- / Col -->

      <!-- Col -->
      <div *ngIf="viewSelect" class="c-card-add-user__col c-card-add-user__col--select" required>
        <mat-form-field class="mat-form-field-nofloating">
          <mat-select [(value)]="selectedInfluence">
            <mat-option value="influences">Influences</mat-option>
            <mat-option value="influencedBy">Influenced by</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- / Col -->

      <!-- Col -->
      <div class="c-card-add-user__col c-card-add-user__col--actions">
        <button type="button" class="btn btn-link text-danger btn-icon btn-icon-only" (click)="remove(i)">
          <svg class="icon ic-20">
            <use href="assets/icons/icons-sprite.svg#delete" />
          </svg>
        </button>
        <button type="button" class="btn btn-link btn-icon btn-icon-only" cdkDragHandle>
          <svg class="icon ic-20">
            <use href="assets/icons/icons-sprite.svg#menu" />
          </svg>
        </button>
      </div>
      <!-- / Col -->
    </div>

  </div>

</div>
