import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'cs';
import { DialogConstants } from 'src/app/constants/dialog.constants';
import { UploadFileComponent } from '../../upload-file/upload-file.component';

@Component({
  selector: 'app-dialog-support',
  templateUrl: './dialog-support.component.html',
  styleUrls: ['./dialog-support.component.scss']
})
export class DialogSupportComponent implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dialogRef: MatDialogRef<DialogSupportComponent>,
      private dialogService: DialogService
    ) {
  }

  ngOnInit(): void {
  }

  public openReportIssueDialog(): void{
    this.dialogService.open(DialogConstants.HELP);
  }
}
