import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})

/**
 * order: 
 * 'asc' (ascendente)
 * 'desc' (descendente)
 */

export class OrderByPipe implements PipeTransform {
  transform(data: any[], key: string, order?: string): any[] {

    if (!Array.isArray(data)) {
      return data;
    }

    if (!key) {
      return data
    }

    return this.sort(data, key, order ? order : 'asc');
  }

  sort(data: any[], key: string, order: string) {
    const value = data.sort((a: any, b: any) => {
      return a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0);
    });
    return order !== 'desc' ? value : value.reverse();
  }

}
