export interface EventTask {
    id: number,
    description: string,
    done: boolean
}


export class EventTask implements EventTask {
    constructor(description: string) {
        this.id = new Date().getTime();
        this.description = description;
        this.done = false;
    }
}