export const UserConstants = {
  TYPE: 7,
  MT: {
    ROOT_ID: 701,
    COUNTRY: 703,
    NAME: 704,
    SURNAME: 705,
    EMAIL:709,
    LOGIN: 720,
    AUTHENTICATION: 724

  },
  MC: {
    ROOT_CONTENT_ID: 701
  },
  MASTER_DATA: {
    AUTHENTICATION: {
      1: 'Local',
      2: 'ADFS',
      3: 'Partners'
    },
    TEST_USER: {
      true: 'Yes',
      false: 'No'
    }
  },
  FILTERS: {
    name:{ input: 'text', type: 'text', model: '', placeholder: 'Filter By Name', label: 'NAME' },
    surname: { input: 'text', type: 'text', model: '', placeholder: 'Filter By Surname', label: 'SURNAME' },
    login:{ input: 'text', type: 'text', model: '', placeholder: 'Filter By UPN', label: 'LOGIN' },
    authentication:{ input: 'text', type: 'masterDataByValue', model: '', placeholder: 'Filter By Authentication', label: 'AUTHENTICATION' },
    email:{ input: 'text', type: 'text', model: '', placeholder: 'Filter By E-mail', label: 'EMAIL' },
    country:{ input: 'text', type: 'text', model: '', placeholder: 'Filter By Country', label: 'COUNTRY' },
   // testUser:{ input: 'text', type: 'masterDataByValue', model: '', placeholder: 'Filter By Test User', label: 'TEST_USER' },
    creationDate:{ input: 'date', type: 'imple_datesRange',  placeholder: 'Filter By Update Date', label: 'creationDate' },
  },
  GRID: [
    {column: 'Name',      field: 'name', type: 'text'},
    {column: 'Surname',   field: 'surname', type: 'text'},
    {column: 'UPN',   field: 'login', type: 'text'},
    {column: 'Authentication', field: 'authentication', type: 'format', format: 'AUTHENTICATION'},
    {column: 'E-mail',    field: 'email', type: 'text'},
    {column: 'Country',   field: 'country', type: 'text'},
    {column: 'Update Date', field: 'creationDate', type: 'date'}
  ]

};
