import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ViewChild, HostBinding, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { EventContact } from 'src/app/models/event-contact.model';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';

@Component({
  selector: 'app-add-repeat',
  templateUrl: './add-repeat.component.html',
  styleUrls: ['./add-repeat.component.scss'],
  providers: [OrderByPipe]
})
export class AddRepeatComponent implements OnInit, OnChanges {

  @Output() callBackEvent: EventEmitter<EventContact[]> = new EventEmitter();

  // Input
  @Input() viewSelect: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() contactList: EventContact[] = [];

  @ViewChild('userInput') userInput!: any;
  @HostBinding('class') hostClass = 'c-add-repeat';

  public height: string = '200px';

  // Add User Onboard
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<EventContact[]>;

  byBrandResponsible = new FormControl('');
  byBrandResponsibleList: string[] = ['Create Goals', 'Create a Plan', 'Certifications', 'Look at the Whole Picture'];
  selectedInfluence: any = 'influences';

  //Dummy users
  users: EventContact[] = [];
  allUsers: EventContact[] = []
  selectedUsers: EventContact[] = [];



  constructor(private orderByPipe: OrderByPipe) {
    this.filteredUsers = this.userCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(400),
      distinctUntilChanged(),
      map((value: string | null) => value ? this._filter(value) : this.orderByPipe.transform(this.allUsers, 'name', 'asc')));
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contactList?.currentValue.length) {
      this.allUsers = changes.contactList.currentValue;
    }
  }

  add(contact: MatChipInputEvent): void {
    const value: any = contact.value;
    // Add our user
    if (value) {
      this.users.push(value as EventContact);
    }

    // Clear the input value
    contact.chipInput!.clear();
    this.userCtrl.setValue(null);
  }

  remove(index: number): void {
    const contact = this.selectedUsers[index];
    this.selectedUsers.splice(index, 1);
    this.allUsers.push(contact);
    this.updateUser();
  }

  removeChip(user: EventContact): void {
    this.users = this.users.filter(u => u.id !== user.id);
    this.allUsers.push(user);
  }


  selected(contact: MatAutocompleteSelectedEvent): void {
    this.allUsers = this.allUsers.filter(u => u.id !== contact.option.value.id);
    this.users.push(contact.option.value);
    this.userInput.nativeElement.value = '';
    this.userCtrl.setValue(null);
  }

  addUsers(users: EventContact[]): void {
    if (users.length) {
      users.forEach(user => {
        this.selectedUsers.push(user);
      });
    }
    this.updateUser();
    this.users = [];
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedUsers, event.previousIndex, event.currentIndex);
  }

  trackById(_index: number, item: any): number {
    return item.id;
  }

  private _filter(value: string): EventContact[] {
    const filterValue = value.toLowerCase();
    const filteredOptions = this.allUsers.filter(user => user.name.toLowerCase().includes(filterValue) || user.mail?.toLowerCase().includes(filterValue));
    this.height = filteredOptions.length < 4 ? `${filteredOptions.length * 50}px` : `200px`;
    return this.orderByPipe.transform(filteredOptions, 'name', 'asc');
  }

  private updateUser() {
    this.callBackEvent.emit(this.selectedUsers);
  }

}
