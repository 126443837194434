import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoginService, CoreService } from 'cs';
import { UserLoginModel } from '../models/user-login.model';
import { DialogService } from 'cs';
import { DialogConstants } from '../constants/dialog.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  isAdminAccess: boolean = false;
  environment: any;
  services: any;
  viewPass: boolean = false;
  idps: any;

  userLogin: UserLoginModel = new UserLoginModel();

  private unsubscribe$ = new Subject<void>();

  error: {
    passwordExpires: boolean;
    login: boolean;
  };

  constructor(
    private loginService: LoginService,
    private coreService: CoreService,
    private dialogService : DialogService,
    ) {
    this.environment = this.coreService.get('environment');
    this.services = this.coreService.get('services');
    this.error = { passwordExpires: false, login: false };
   }

   ngOnInit(): void {
    this.listenerStorage();
    this.loginService.getIdProviders().subscribe(
      (resp) => {
        this.idps = resp
      }

    );
    this.error = { passwordExpires: false, login: false };
    /*idpsObservable$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      resp => this.idps = resp
    );*/
  }

  listenerStorage(): void {
    if (localStorage.samlAuthentication) {
      this.loginService.loginADFS();
    } else if (localStorage.samlError && localStorage.samlError !== 'false') {
      this.dialogService.open(DialogConstants.LOGIN_ERROR);
      localStorage.setItem('samlError', 'false');
      localStorage.setItem('samlErrorMessage', 'false');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  havasLogin(idp: string) {
    window.location.href = `${this.environment.oauthEndpoint}${this.services.SAML_LOGIN}${idp}`;
  }

  login(form: NgForm) {
    if (form.invalid) {
      return;
    }

    // this.alertService.showAlert(AlertMessages.PLEASE_WAIT);

    let deviceId: any = localStorage.getItem(this.environment.nameStorage + 'DeviceId');
    if (!deviceId) {
      deviceId = Math.floor(100000000 + Math.random() * 900000000);
      localStorage.setItem(this.environment.nameStorage + 'DeviceId', '' + deviceId);
      const currentDate = new Date();
      localStorage.setItem(this.environment.nameStorage + 'OpDate', '' + currentDate.getTime());
      localStorage.setItem(this.environment.nameStorage + 'SessionId', '' + (deviceId + currentDate.getTime()));
      localStorage.setItem(this.environment.nameStorage + 'ClickNumber', '1');
    }

    this.loginService.login(this.userLogin, deviceId, null, null)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe( ( resp: any ) => { },
      ( err: any ) => {
        this.userLogin.loginPassword = '';
        this.dialogService.open(DialogConstants.LOGIN_ERROR);
      });
  }

}
