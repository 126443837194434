import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit, Inject } from '@angular/core';

import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadFileComponent } from '../../upload-file/upload-file.component';

@Component({
  selector: 'app-dialog-add-document',
  templateUrl: './dialog-add-document.component.html',
  styleUrls: ['./dialog-add-document.component.scss']
})
export class DialogAddDocumentComponent implements OnInit {


  //Add User Onboard
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: Observable<string[]>;

  //Dummy users
  users: string[] = ['John S. Tran'];
  allUsers: string[] = ['Celena J. Whorton', 'Mark D. Matthews', 'Ruth J. Shock', 'Clarence S. Williams', 'Rick G. Ray'];

  @ViewChild('userInput') userInput!: any;
  @ViewChild(UploadFileComponent) uploadFileComponent: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data:any,
                private dialogRef: MatDialogRef<DialogAddDocumentComponent>) {
      this.filteredUsers = this.userCtrl.valueChanges.pipe(
        startWith(null),
        map((user: string | null) => user ? this._filter(user) : this.allUsers.slice()));
    }

    add(event: MatChipInputEvent): void {
      const value = (event.value || '').trim();

      // Add our user
      if (value) {
        this.users.push(value);
      }

      // Clear the input value
      event.chipInput!.clear();
      this.userCtrl.setValue(null);
    }

    remove(user: string): void {
      const index = this.users.indexOf(user);
      if (index >= 0) {
        this.users.splice(index, 1);
      }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
      this.users.push(event.option.viewValue);
      this.userInput.nativeElement.value = '';
      this.userCtrl.setValue(null);
    }

    private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.allUsers.filter(user => user.toLowerCase().includes(filterValue));
    }

    saveDocument(){
      const files = this.uploadFileComponent.returnFilesDocument();
      this.dialogRef.close({files: files});
    }


    ngOnInit(): void {

    }





}
