import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { AuthGuard, RoleGuard, AlreadyLogin } from 'node_modules/cs'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full'
  },
  {
    path: 'home',
    redirectTo: 'events',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [AlreadyLogin],
    component: LoginComponent
  },
  {
    path: 'events',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () => import(`./events/events.module`).then(module => module.EventsModule),
  },
  {
    path: 'contacts',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () => import(`./contacts/contacts.module`).then(module => module.ContactsModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, RoleGuard],
    loadChildren: () => import(`./admin/admin.module`).then(module => module.AdminModule),
    //  data: {
    //   expectedRoles: [RolAdmin, RolAdminModule]
    //  }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
