<div class="c-sidebar">

  <div class="c-sidebar__header">
    <h2 class="c-sidebar__title">Event Information</h2>
  </div>

  <div class="c-sidebar__body">
    <div class="row row-cols-2 g-4">
      <div class="col-12">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Brand</span>
          <span appDataLabelText>{{event.brand}}</span>
        </div>
      </div>
      <div class="col">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Start - End date</span>
          <span appDataLabelText>{{event.eventDate[0] | date:'dd/MM/yyyy'}} - {{event.endDate[0] | date:'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class="col">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Due Date</span>
          <span appDataLabelText>{{event.dueDate[0] | date:'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class="col">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Event Type</span>
          <span appDataLabelText>{{event.eventType}}</span>
        </div>
      </div>
      <div class="col">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Category</span>
          <span appDataLabelText>Engage</span>
        </div>
      </div>
      <div class="col">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Action Type</span>
          <span appDataLabelText>{{event.actionType}}</span>
        </div>
      </div>
      <div class="col">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Scale</span>
          <span appDataLabelText>{{event.scale}}</span>
        </div>
      </div>
      <div class="col-12">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Location</span>
        </div>
        <div class="fs-sm">
          <p>{{event.address}}</p>
          <a demoHidden class="fs-xs" href="https://goo.gl/maps/KUtYWAx4HjiJ8uzM9" target="_blank">Get me there</a>
        </div>
      </div>
      <div class="col-12">
        <div appDataLabel size="sm">
          <span appDataLabelCaption>Description</span>
        </div>
          <p>{{event.description}}</p>
      </div>
    </div>
  </div>

</div>
