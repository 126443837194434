import { Component, ViewChild, HostBinding, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EventTask } from 'src/app/models/event-task.model';

@Component({
  selector: 'app-forms-tasks',
  templateUrl: './forms-tasks.component.html',
  styleUrls: ['./forms-tasks.component.scss']
})
export class FormsTasksComponent {

  @Output() callBackEvent: EventEmitter<EventTask[]> = new EventEmitter();

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  taskInput: any = '';
  taskInputList: EventTask[] = [];

  @ViewChild('userInput') userInput!: any;
  @HostBinding('class') hostClass = 'c-add-repeat';

  constructor() { }

  addTask(val: string): void {
    console.log(val);
    const value = (val || '').trim();

    // Add task
    if (value) {
      this.taskInputList.push(new EventTask(value));
      this.taskInput = "";
      this.updateTasks();
    }

  }

  remove(index: number): void {
    this.taskInputList.splice(index, 1);
    this.updateTasks();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.taskInputList, event.previousIndex, event.currentIndex);
  }

  trackById(_index: number, item: any): number {
    return item.id;
  }

  private updateTasks() {
    this.callBackEvent.emit(this.taskInputList);
  }

}
