import { Injectable } from '@angular/core';
import { UtilsService, MetadataUtilsService, HttpService, MetadataSearchDto } from 'node_modules/cs';
import { UserConstants } from '../constants/user.constants';
import { services } from '@services';
import { environment } from '@environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private metadataUtilsService: MetadataUtilsService,
              private utilsService: UtilsService,
              private httpService: HttpService,
              private http: HttpClient) { }

  searchDto: MetadataSearchDto = {
    pagination: {
      page: 0,
      itemsPerPage: 20
    },
    lang: 'en',
    resourcePermission: 1,
    globalResourceTypeId: UserConstants.TYPE,
    metadataMapSearchOr: new Map<number, number[]>(),
    metadataMapSearchAnd: new Map<number, number[]>(),
    metadataContentIdsSearch: new Map<number, number[]>(),
    metadataIdsBetween: new Map<number, number[]>(),
    columnDataBaseBetween: {}
  };


  private checkField = (value: any) => value && value.length ? value[0] : null;

  initialize(item: any, full?: boolean) {
    const globalResourceItem: any = {};
    globalResourceItem.globalResourceId = item.globalResourceId;
    globalResourceItem.globalResourceGroupId = item.globalResourceGroupId;
    globalResourceItem.globalResourceTypeId = item.globalResourceTypeId;
    globalResourceItem.creationDate = item.creationDate;

    globalResourceItem.name = full ? item.name : this.checkField(item.name);
    globalResourceItem.surname = full ? item.surname : this.checkField(item.surname);
    globalResourceItem.login = full ? item.login : this.checkField(item.login);
    globalResourceItem.email = full ? item.email : this.checkField(item.email);
    globalResourceItem.authentication = full ? item.authentication : this.checkField(item.authentication);
    globalResourceItem.active= full ? item.active : this.checkField(item.active);
    globalResourceItem.testUser= full ? item.testUser : this.checkField(item.testUser);
    globalResourceItem.showContact= full ? item.showContact : this.checkField(item.showContact);



    if(full){
      //Check Perms
      globalResourceItem.admin = item.admin;
      globalResourceItem.clientLead = item.clientLead;
      globalResourceItem.basic  = item.basic;
      globalResourceItem.globalClient = item.globalClient;
      globalResourceItem.globalCountry = item.globalCountry;
      // Arrays Perms
      globalResourceItem.campaignGeographyPerm = item.campaignGeographyPerm;
      globalResourceItem.agencyPerm = item.agencyPerm;
      globalResourceItem.clientPerm = item.clientPerm;
      globalResourceItem.adminPerm = item.adminPerm;
      globalResourceItem.countryMetadataContentId = item.countryMetadataContentId;
      globalResourceItem.file = {};
      globalResourceItem.file.contentIdMetadataContentId = item.contentIdMetadataContentId;
    } else {
      globalResourceItem.country=  this.checkField(item.country);
    }
    return globalResourceItem;
  }

  getAll(fromScroll: boolean) {
    this.searchDto.pagination!.page = fromScroll ? this.searchDto.pagination!.page + 1 : 1;
    let url = services.GLOBAL_RESOURCES;
    if (environment.offline) {
        url += '/7/7_List.json'; // Dummy Users 
    }
    console.log(url);
    return this.httpService.getAll(url, this.searchDto);
  }

  getOne(globalResourceGroupId: number, globalResourceTypeId?: any) {
    let url = services.USER_PROFILE_ADMIN;
    if (environment.offline) {
      url += `/${globalResourceTypeId}/${globalResourceGroupId}.json`;
    }
    return this.httpService.getOne(url, globalResourceGroupId);
  }

  save(dto: any, globalResourceTypeId: number, files?: any) {
    let url = services.USER_PROFILE_ADMIN;
    if (environment.offline) {
      let globalResourceId = dto.globalResourceId ?dto.globalResourceId : 0;
      url += `/${globalResourceTypeId}/${globalResourceId}.json`;
    }
    return this.httpService.save(url, dto, globalResourceTypeId, files);
  }


  delete(globalResourceGroupId: number, globalResourceTypeId: number) {
    return this.httpService.delete(services.GLOBAL_RESOURCES, globalResourceGroupId, globalResourceTypeId);
  }


  existLogin(login: string, authentication: string) {
    const url = `${environment.apiEndpoint}${services.LOGIN_EXISTS}`;
    return this.http.get(url, { params: { login, authentication } });
  }

  existUser(login: string): Observable<any> {
    const url = `${environment.apiEndpoint}${services.USER_EXISTS}`;
    return this.http.get(url, { params: { login } });
  }

  getTemplatePermByGroup(templatePermId: number): Observable<any>  {
    let url = `${environment.apiEndpoint}${services.TEMPLATE_PERM_TREE_BY_GROUP}`;
    if (!environment.offline) {
        url += `/${templatePermId}`;
    }
    return this.http.get(url);
  }

}
