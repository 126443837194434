import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog-save-buttons',
  templateUrl: './dialog-save-buttons.component.html',
  styleUrls: ['./dialog-save-buttons.component.scss']
})
export class DialogSaveButtonsComponent implements OnInit {

  @Input() isDisabled: any;
  @Input() enabledDelete: boolean = false;
  @Output() saveDocumentEvent: any = new EventEmitter();
  @Output() deleteDocumentEvent: any = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  saveDocument() {
    this.saveDocumentEvent.emit();
  }

  deleteDocument() {
    this.deleteDocumentEvent.emit();
  }

}
