<div class="c-add-repeat__bar">

  <div class="c-add-repeat__bar__col c-add-repeat__bar__col--user">
    <mat-form-field class="mat-form-field-nofloating">
      <mat-label>Add Task</mat-label>
      <input #inputTask matInput [(ngModel)]="taskInput" maxlength="250" cdkTextareaAutosize cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="4">
      <mat-hint align="end"></mat-hint>

    </mat-form-field>
  </div>

  <div class="c-add-repeat__bar__col c-add-repeat__bar__col--actions">
    <button class="btn btn-icon btn-icon-left btn-primary" [disabled]="!inputTask.value"
      (click)="addTask(inputTask.value)">
      <svg class="icon ic-16">
        <use href="assets/icons/icons-sprite.svg#plus" />
      </svg>Add
    </button>
  </div>
</div>
<div cdkDropList class="c-add-repeat__list" (cdkDropListDropped)="drop($event)">

  <div class="c-add-repeat__list__item" *ngFor="let task of taskInputList; let i = index; trackBy:trackById">
    <div class="c-card-add-user" cdkDrag>
      <!-- Col -->
      <div class="c-card-add-user__col c-card-add-user__col--user">
        <div appDataLabelGroup>
          <div appDataLabel size="sm">
            <span appDataLabelText>{{task.description}}</span>
          </div>
        </div>
      </div>
      <!-- / Col -->
      <!-- Col -->
      <div class="c-card-add-user__col c-card-add-user__col--actions">
        <button type="button" class="btn btn-link text-danger btn-icon btn-icon-only" (click)="remove(i)">
          <svg class="icon ic-20">
            <use href="assets/icons/icons-sprite.svg#delete" />
          </svg>
        </button>
        <button type="button" class="btn btn-link btn-icon btn-icon-only" cdkDragHandle>
          <svg class="icon ic-20">
            <use href="assets/icons/icons-sprite.svg#menu" />
          </svg>
        </button>
      </div>
      <!-- / Col -->
    </div>

  </div>

</div>
