import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {NgForm} from '@angular/forms';
import {combineLatest, forkJoin} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogService, MetadataSearchService} from 'cs';
import {EventConstants} from 'src/app/constants/event.constants';
import {EventDto} from 'src/app/models/event-dto.model';
import {EventForm} from 'src/app/models/event-form.model';
import {TreeMetadata} from 'src/app/models/tree-metadata.model';
import {ClientService} from 'src/app/services/client.service';
import {GenericGlobalService} from 'src/app/services/generic-global.service';
import {EventService} from 'src/app/services/event.service';
import {EventContact} from 'src/app/models/event-contact.model';
import {EventTask} from 'src/app/models/event-task.model';
import {CeaComponent} from '../../cea/cea.component';


@Component({
  selector: 'app-dialog-add-event',
  templateUrl: './dialog-add-event.component.html',
  styleUrls: ['./dialog-add-event.component.scss']
})
export class DialogAddEventComponent extends CeaComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('userInput') userInput!: any;
  @ViewChild('eventForm') eventForm: NgForm | undefined;


  currentDate: Date = new Date();

  event: EventForm = new EventForm();
  cliIdEvent: number = 0;
  cliIdContact: number = 0;
  byTypologyList: TreeMetadata[] = [];
  byActionTypeList: TreeMetadata[] = [];
  byMarketList: TreeMetadata[] = [];
  byScaleList: TreeMetadata[] = [];
  byBrandList: TreeMetadata[] = [];
  contactList: EventContact[] = [];
  havasTeamList: EventContact[] = [];
  havasTeamSelectedList: EventContact[] = [];
  owenrContactList: EventContact[] = [];
  owenrContactSelectedList: EventContact[] = [];
  clientTeamAttendingSelectedList: EventContact[] = [];
  taskctList: EventTask[] = [];
  isValidFormContat: boolean = false;
  enabledEdit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogAddEventComponent>,
    private metadataSearchService: MetadataSearchService,
    private genericGlobalService: GenericGlobalService,
    private clientService: ClientService,
    private dialogService: DialogService,
    private eventService: EventService
  ) {
    super();
  }

  ngOnInit(): void {
    this.enabledEdit = this.data ? true : false;
    if (this.enabledEdit) {
      this.event = new EventForm(this.data);
    }
    this.getClient();
  }

  ngAfterViewInit(): void {
  }


  save() {

    this.dialogService.openSpinner();

    if (!this.checkFrom()) {
      // TODO: revisar error formulario
      console.log('error formulario');
      this.dialogService.close();
      return;
    }

    if (this.enabledEdit) {
      return
    }

    this.genericGlobalService.save(
      new EventDto(this.event, this.cliIdEvent, this.havasTeamSelectedList, this.owenrContactSelectedList, this.clientTeamAttendingSelectedList, this.taskctList),
      EventConstants.TYPE).subscribe(
      (resp: any) => {
        this.dialogService.close();
        this.dialogRef.close({success: true, result: resp});
        this.eventService.updateEventList();
      },
      (e: any) => {
        this.dialogService.close();
        this.dialogRef.close({success: false, result: e});
      }
    );
  }

  delete() {
    this.eventService.deleteEVent(this.data.id).subscribe(result => {
      this.dialogRef.close({success: true, result});
    }, (e: any) => {
      this.dialogService.close();
      this.dialogRef.close({success: false, result: e});
    });
  }

  addOwner(contacts: EventContact[]) {
    this.owenrContactSelectedList = contacts;
    this.updateFormContact();
  }

  addHavasTeam(contacts: EventContact[]) {
    this.havasTeamSelectedList = contacts;
    this.updateFormContact();
  }

  addClientTeamAttending(contacts: EventContact[]) {
    this.clientTeamAttendingSelectedList = contacts;
    this.updateFormContact();
  }

  addTask(tasks: EventTask[]) {
    this.taskctList = tasks;
  }

  trackById(_index: number, item: TreeMetadata): number {
    return item.metadataContentId;
  }

  private getClient(): void {
    combineLatest([this.clientService.idSelectedByEvent$, this.clientService.idSelectedByContact$]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([cliIdEvent, cliIdContact]: [number, number]) => {
      if (cliIdEvent && cliIdContact) {
        this.cliIdEvent = cliIdEvent;
        this.cliIdContact = cliIdContact;
        this.initDataEvents();
      }
    });
  }

  private initDataEvents(): void {

    const scale$ = this.metadataSearchService.getGRTreeMetadataOneLevelByMetadata(EventConstants.MT.ROOT_ID, EventConstants.MT.SCALE_METADATA_ID);
    const market$ = this.metadataSearchService.getGRTreeMetadataOneLevelByMetadata(this.cliIdEvent, EventConstants.MT.MARKET_METADATA_ID);
    const typology$ = this.metadataSearchService.getGRTreeMetadataOneLevelByMetadata(this.cliIdEvent, EventConstants.MT.TYPOLOGY_METADATA_ID);
    const actionType$ = this.metadataSearchService.getGRTreeMetadataOneLevelByMetadata(this.cliIdEvent, EventConstants.MT.ACTION_METADATA_ID);
    const brand$ = this.metadataSearchService.getGRTreeMetadataOneLevelByMetadata(this.cliIdEvent, EventConstants.MT.BRAND_SEGMENT_METADATA_ID);

    this.eventService.getContactList(this.cliIdContact).pipe(takeUntil(this.destroyed$)).subscribe((contatcs: any) => {
      if (contatcs && contatcs.length) {
        this.contactList = contatcs;
        this.havasTeamList = contatcs.filter((c: any) => !c.adfs); // TODO: Eliminar negacion
        this.owenrContactList = contatcs.filter((c: any) => !c.adfs);
      }
    });

    forkJoin([scale$, market$, typology$, actionType$, brand$]).pipe(takeUntil(this.destroyed$), map(
      ([scale, market, typology, actionType, brand]: Array<any[]>) => {
        return [scale || [], market || [], typology || [], actionType || [], brand || []];
      })).subscribe(
      ([scale, market, typology, actionType, brand]: Array<any[]>) => {
        this.byActionTypeList = actionType;
        this.byBrandList = brand;
        this.byMarketList = market;
        this.byScaleList = scale;
        this.byTypologyList = typology;
      },
      (e: any) => {
        // TODO: revisar error
        console.log('initDataEvents error => ', e);
      });
  }

  private checkFrom(): boolean {
    // validacion de campos oblicatorios
    if (!this.event.name.trim().length) {
      return false;
    }

    if (!this.event.description.trim().length) {
      return false;
    }

    if (!this.byActionTypeList.find(a => +this.event.actionType === a.metadataContentId)) {
      return false;
    }

    if (!this.byScaleList.find(a => +this.event.scale === a.metadataContentId)) {
      return false;
    }

    if (!this.byBrandList.filter(a => this.event.brand.some(b => b === a.metadataContentId))) {
      return false;
    }

    if (!this.byMarketList.find(a => +this.event.market === a.metadataContentId)) {
      return false;
    }

    if (!this.byTypologyList.find(a => +this.event.typology === a.metadataContentId)) {
      return false;
    }

    if (!this.havasTeamSelectedList.length) {
      return this.APP.IS_DEMO || false;
    }

    if (!this.owenrContactSelectedList.length) {
      return this.APP.IS_DEMO || false;
    }

    if (!this.clientTeamAttendingSelectedList.length) {
      return this.APP.IS_DEMO || false;
    }

    return true;
  }

  private updateFormContact() {
      this.isValidFormContat = !!(this.havasTeamSelectedList.length && this.owenrContactSelectedList.length && this.clientTeamAttendingSelectedList.length);
  }

}
