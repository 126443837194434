export const ClientConstants = {
  BY_EVENT: {
    METADATA_ID: 3001,
    PARENT_ID: 3000,
  },
  BY_CONTACT: {
    METADATA_ID: 4001,
    PARENT_ID: 4000,
  }
};
