import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private previousUrl = '/events/my-events';
  private currentUrl = '/events/my-events';

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof RoutesRecognized),
        pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects || this.previousUrl;
        this.currentUrl = events[1].urlAfterRedirects || this.currentUrl;
      });
  }

  init() { }

  goBack() {
    this.router.navigate([`${this.previousUrl}`]);
  }
}


