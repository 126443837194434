export interface TreeMetadata {
    hasChildren: boolean,
    metadataContentId: number
    metadataContentText: string
    metadataId: number
    metadataIdText: string
    parentId: number
}

export class TreeMetadata implements TreeMetadata {
    constructor(data?: any) {
        this.hasChildren = data?.hasChildren || false;
        this.metadataContentId = data?.metadataContentId || 0;
        this.metadataContentText = data?.metadataContentText || '';
        this.metadataId = data?.metadataId || 0
        this.metadataIdText = data?.metadataIdText || '';
        this.parentId = data?.parentId || 0;
    }
}
