import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appWhitespacesValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: WhitespacesDirective,
    multi: true
  }]
})

export class WhitespacesDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return (control.value || '').trim().length
      ? null
      : { 'whitespacesInvalid': true };
  }
}
